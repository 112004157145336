import React from 'react';
import './WhatsappButton.scss';

import Init from '../../../Init/Init';
import { buildWaLink } from '../../../Util/Util';

function WhatsappButton(props) {
    const { FontAwesomeIcon, config } = Init;
    const { whatsappIcon } = config;

    return (
        <>
            <div className="whatsapp-button-container">
                <a href={ buildWaLink('', '', true) } className='whatsapp-button' target='_blank'>
                    <img className="whatsapp-icon" src={whatsappIcon} alt="Whatsapp" /> <span className='button-label'>Cek transaksi pesanan Anda</span> <FontAwesomeIcon className='whatsapp-button-icon' icon={['fas', 'chevron-right']} />
                </a>
            </div>
        </>
    );
}

export default WhatsappButton;