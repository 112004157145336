import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Formik, Field, Form } from 'formik';
import './AccountDetailPage.scss';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';

import { connect } from 'react-redux';
import { accountDelete, authenticate } from '../../Redux/Auth/auth-action';

import authAPI from '../../Data/Auth';
import { Modal, Toast } from 'react-bootstrap';

import Init from '../../Init/Init';

const { FontAwesomeIcon } = Init;

const { saveAccount } = authAPI;

const mapStateToProps = (state) => {
    return {
        customer: state.auth.data,
        dataLoaded: state.auth.isLoaded,
        isLogin: state.auth.isLogin,
        isAuthenticating: state.auth.isAuthenticating
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authenticate: () => dispatch(authenticate()),
        deleteAccount: () => dispatch(accountDelete())
    }
}

const AccountDetailPage = (props) => {
    const [ data, setData ] = useState(null);
    const [ prohibitSubmit, setProhibitSubmit ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(true);
    const { authenticate } = props;

    const navigate = useNavigate();

    const [ error, setError ] = useState({});
    const [toastContent, setToastContent] = useState({
        message: '',
        show: false
    });

    const [ showModal, setShowModal ] = useState(false);

    useEffect( () => {
        window.scroll({
            top: 0,
            behaviour: 'smooth'
        });

        props.checkPage('pengaturan-akun');
    }, []);

    useEffect( () => {
        if( props.customer.id ) {
            setData({
                customer_username: props.customer.customer_username,
                customer_phone_number: props.customer.customer_phone_number
            });
        } else {
            setData(null);
        }
    }, [ props.customer ]);

    useEffect( () => {
        let formFilled = true;
        
        if(data !== null) {
            setIsLoading( false );

            for(let key in data) {
                if(data[key] === '' || data[key] === null) {
                    formFilled = false;
                    break;
                }
            }

            setProhibitSubmit( !formFilled );
        } else {
            if( props.isAuthenticating ) {
                setIsLoading( true );
            } else if( !props.isLogin ) {
                navigate('/');
            }
        }

    }, [ data ]);

    const save = (values) => {
        console.log("Validate on Submit");
        
        ( async () => {
            const response = await saveAccount(values);

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                authenticate();
                setToastContent({
                    message: response.message,
                    show: true
                });
                setError({});
            } else {
                const errorMessages = response.data;
                const errorData = {};

                for(let index in errorMessages) {
                    let item = errorMessages[index];

                    errorData[item.field] = item.message;
                }

                setError({
                    ...errorData
                });
            }
        } )();
    };

    const validateInput = (name, value) => {
        const dataCopy = {...data};
        
        if( dataCopy.hasOwnProperty(name) && dataCopy[name] !== value ) {
            console.log("Validate on Change");
            dataCopy[name] = value;
    
            setData({
                ...dataCopy
            });
        }
    };

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };

    const closeModal = () => {
        setShowModal(false);
    }

    const openModal = () => {
        setShowModal(true);
    }

    const deleteAccount = async () => {
        props.deleteAccount();
    }

    const deleteConfirmation = () => {
        openModal();
    };
    
    return (
        <div className="account-detail-page">
            {/* 
                Account Detail Page:
                0.1 Toast
                1. Form:
                    - Username
                    - Phone Number
                2. Submit Button
            */}
            <Toast 
                className='success-toast'
                show={toastContent.show}
                // autohide?: boolean;
                // animation?: boolean;
                // delay?: number;
                // onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
                // show?: boolean;
                // transition?: TransitionComponent;
                // bg={''}
            >
                <Toast.Body className='success-toast-body'>
                    <div className="message">
                        { toastContent.message }
                    </div>
                    <button className='btn-close' onClick={closeToast}>
                    </button>
                </Toast.Body>
            </Toast>
            <Modal show={ showModal } onHide={ closeModal } className="account-deletion-modal">
                <Modal.Header className='modal-title text-danger' closeButton>
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} /> <span> Penghapusan Akun </span>
                </Modal.Header>
                <Modal.Body>
                    Apakah anda yakin ingin menghapus akun anda?
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-cancel' onClick={ () => closeModal() }>
                        Tidak
                    </button>
                    <button className="btn-confirm" onClick={ () => deleteAccount() }>
                        Iya
                    </button>
                </Modal.Footer>
            </Modal>
            {
                !isLoading ? (
                    <>
                        <Formik
                            initialValues={ data }
                            onSubmit={ (values) => save(values) }
                            validateOnChange={ false }
                        >
                            <Form className="page-form">
                                <label className="form-label" htmlFor="customer_username">Username</label>
                                <Field className={`form-input ${ error.customer_username ? 'form-error' : '' }`} validate={ (value) => validateInput("customer_username", value) } name="customer_username" type="text" placeholder={"Masukkan username"} />
                                <small className="text-validation text-danger">
                                    { error.customer_username }
                                </small>
                                
                                <label className="form-label" htmlFor="customer_phone_number">No Telepon</label>
                                <Field className={`form-input ${ error.customer_phone_number ? 'form-error' : '' }`} validate={ (value) => validateInput("customer_phone_number", value) } name="customer_phone_number" type="text" placeholder={"Masukkan nomor telepon"} />
                                <small className="text-validation text-primary">
                                    Nomor WhatsApp yang aktif.
                                </small>
                                <small className="text-validation text-danger">
                                    { error.customer_phone_number }
                                </small>

                                <button disabled={ prohibitSubmit } className='form-button' type="submit">
                                    Ubah
                                </button>
                            </Form>
                        </Formik>
                        <button className='btn-delete-account' onClick={ deleteConfirmation }>
                            Hapus Akun
                        </button>
                    </>
                ) : (
                    <LoadingPage />
                )
            }
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailPage);