const appleConfig = {
    clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
    teamId: process.env.REACT_APP_TEAM_ID,
    keyId: process.env.REACT_APP_KEY_ID,
    redirectUrl: process.env.REACT_APP_APPLE_REDIRECT_URL,
    // redirectUrl: 'https://devapi.landack.id/api/v1/oauth/apple',
    scope: process.env.REACT_APP_APPLE_SCOPE,
    responseMode: 'form_post',
    usePopup: true
}

export default appleConfig;