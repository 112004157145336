import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Formik, Field, Form } from 'formik';
import './EventRegistrationPage.scss';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';

import { connect } from 'react-redux';
import { authenticate } from '../../Redux/Auth/auth-action';

import { eventForm, eventRegistration }  from '../../Data/Events';
import { Toast } from 'react-bootstrap';
import Init from '../../Init/Init';
import { numberFormatting } from '../../Util/Util';

const { constants } = Init;

const mapStateToProps = (state) => {
    return {
        customer: state.auth.data,
        dataLoaded: state.auth.isLoaded
    }
};

const EventRegistrationPage = (props) => {
    const [ data, setData ] = useState(null);
    const [ prohibitSubmit, setProhibitSubmit ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ form, setForm ] = useState([]);
    const [ pricing, setPricing ] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const [ error, setError ] = useState({});
    const [toastContent, setToastContent] = useState({
        message: '',
        show: false
    });

    useEffect( () => {
        window.scroll({
            top: 0,
            behaviour: 'smooth'
        });

        props.checkPage('pendaftaran-event');
    }, []);

    useEffect( () => {
        if (location.hasOwnProperty('state') && location.state.hasOwnProperty('eventId')) {
            const updatedData = {
                name: "",
                email: "",
                whatsapp_no: ""
            };

            ( async () => {
                const eventId = location.state.eventId;
                const response = await eventForm(eventId);

                if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                    setForm(response.data.form);
                    setPricing(response.data.pricing);

                    updatedData.eventPriceId = response.data.pricing[0].id;
                    
                    for( let index in response.data.form ) {
                        let item = response.data.form[index];

                        updatedData[item.input_name] = '';
                    }
                }

                if( props.customer.id ) {
                    // const updatedData = {
                    //     name: props.customer.customer_fullname,
                    //     email: props.customer.customer_email,
                    //     whatsapp_no: props.customer.customer_phone_number
                    // };
                    updatedData.name = props.customer.customer_fullname;
                    updatedData.email = props.customer.customer_email;
                    updatedData.whatsapp_no = props.customer.customer_phone_number;   
                }

                setData(updatedData);
            } )();

            
        } else {
            navigate('/', {
                replace: false
            });
        }
    }, [ props.customer ]);

    useEffect( () => {
        let formFilled = true;
        if(data !== undefined && data !== null) {
            setIsLoading(false);

            console.log("Check form filled");

            for(let key in data) {
                if(data[key] === '' || data[key] === null) {
                    formFilled = false;
                    break;
                }
            }

            if ( !data.hasOwnProperty('whatsapp_no') || data.whatsapp_no === '' ) formFilled = false;

            setProhibitSubmit( !formFilled );
        } else {
            setIsLoading( true );
        }

    }, [ data ]);

    const handleFile = (name, file) => {
        let dataCopy = {...data};
        dataCopy[name] = {
            name: file.name,
            type: file.type,
            size: file.size,
            lastModified: file.lastModified
        };
        
        const reader = new FileReader();

        reader.onload = () => {
            dataCopy[name].fileContent = reader.result.split(",").pop();

            setData({
                ...dataCopy
            });
        }

        reader.readAsDataURL(file);
    }

    const validateInput = (name, value, isFile = false) => {
        console.log("--- Validate input ---");

        const dataCopy = {...data};

        const dataHasProp = dataCopy.hasOwnProperty(name);
        const dataUpdate = dataCopy[name] !== value;
        const dataUndefined = dataCopy[name] === undefined;

        if( (dataHasProp && dataUpdate) || dataUndefined ) {
            
            if( isFile ) {
                let file = value.target.files[0];

                handleFile(name, file);
            } else {
                dataCopy[name] = value !== undefined ? value : '';
                
                setData({
                    ...dataCopy
                });
            }
    
        }
    };

    const save = (values) => {
        console.log("Validate on Submit");
        // const request = {};

        // for(let index in form) {
        //     let formItem = form[index];

        //     request[formItem.input_name] = values[formItem.input_name];
        // }

        let eventPriceId = values.eventPriceId;

        let phoneNumber = data.whatsapp_no;
        let name = data.name;
        let email = data.email;
        
        ( async () => {
            const response = await eventRegistration(location.state.eventId, eventPriceId, data, name, email, phoneNumber);
            
            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {

                setToastContent({
                    message: response.message,
                    show: true
                });
                
                if( response.data.hasOwnProperty('paymentLink') && response.data.paymentLink !== null ) {
                    window.open( response.data.paymentLink, '_blank' );
                    setProhibitSubmit(true);
                } else {
                    setProhibitSubmit(true);
                    setTimeout( () => {
                        navigate('/', {
                            replace: true
                        });
                    }, 5000 );
                }

            } else {
                const errorMessages = response.data;
                const errorData = {};

                for(let index in errorMessages) {
                    let item = errorMessages[index];

                    errorData[item.field] = item.message;
                }

                setError({
                    ...errorData
                });
            }
        } )();
    };

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };
    
    return (
        <div className="profile-detail-page">
            {/* 
                Profile Detail Page:
                0.1 Toast
                1. Form:
                    - Fullname
                    - Birthday
                    - Gender
                2. Submit Button
            */}
            <Toast 
                className='success-toast'
                show={toastContent.show}
                // autohide?: boolean;
                // animation?: boolean;
                // delay?: number;
                // onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
                // show?: boolean;
                // transition?: TransitionComponent;
                // bg={''}
            >
                <Toast.Body className='success-toast-body'>
                    <div className="message">
                        { toastContent.message }
                    </div>
                    <button className='btn-close' onClick={closeToast}>
                    </button>
                </Toast.Body>
            </Toast>
            {
                !isLoading ? (
                    <Formik
                        initialValues={ data }
                        onSubmit={ (values) => save(values) }
                        validateOnChange={ false }
                    >
                            {
                                () => (
                                    <Form className="page-form">
                                        <label className="form-label" htmlFor="name">Nama Lengkap</label>
                                        <Field 
                                            id="name" 
                                            className={`form-input ${ error.name ? 'form-error' : '' }`} 
                                            name="name" 
                                            type="text" 
                                            placeholder={"Masukkan nama lengkap"} 
                                            defaultValue={ data?.name } 
                                            validate={ (value) => validateInput('name', value) }
                                        />
                                        <small className="text-validation text-danger">
                                            { error.name }
                                        </small>
                                        
                                        <label className="form-label" htmlFor="email">Email</label>
                                        <Field 
                                            id="email" 
                                            className={`form-input ${ error.email ? 'form-error' : '' }`} 
                                            name="email" 
                                            type="email"
                                            defaultValue={ data?.email } 
                                            validate={ (value) => validateInput('email', value) }
                                        />
                                        <small className="text-validation text-danger">
                                            { error.email }
                                        </small>

                                        <label className="form-label" htmlFor="whatsapp_no">No. Telepon</label>
                                        <Field 
                                            id="whatsapp_no" 
                                            className={`form-input ${ error.whatsapp_no ? 'form-error' : '' }`} 
                                            name="whatsapp_no" 
                                            type="text" 
                                            defaultValue={ data?.whatsapp_no } 
                                            validate={ (value) => validateInput('whatsapp_no', value) }
                                        />
                                        <small className="text-validation text-danger">
                                            { error.whatsapp_no }
                                        </small>
                                        
                                        {
                                            form.map( (item, index) => {
                                                switch( item.input_type ) {
                                                    case constants.eventInputType.text:
                                                        return (
                                                            <Fragment key={index}>
                                                                <label className="form-label" htmlFor={ item.input_name }>{ item.input_label }</label>
                                                                <Field id={ item.input_name } className={`form-input ${ error[item.input_name] ? 'form-error' : '' }`} validate={ (value) => validateInput(item.input_name, value) } name={ item.input_name } type="text" />
                                                                <small className="text-validation text-danger">
                                                                    { error[item.input_name] }
                                                                </small>
                                                            </Fragment>
                                                        );
                                                    case constants.eventInputType.textarea:
                                                        return (
                                                            <Fragment key={index}>
                                                                <label className="form-label" htmlFor={ item.input_name }>{ item.input_label }</label>
                                                                <Field id={ item.input_name } className={`form-input ${ error[item.input_name] ? 'form-error' : '' }`} validate={ (value) => validateInput(item.input_name, value) } name={ item.input_name } as='textarea' />
                                                                <small className="text-validation text-danger">
                                                                    { error[item.input_name] }
                                                                </small>
                                                            </Fragment>
                                                        );
                                                    case constants.eventInputType.number:
                                                        return (
                                                            <Fragment key={index}>
                                                                <label className="form-label" htmlFor={ item.input_name }>{ item.input_label }</label>
                                                                <Field id={ item.input_name } className={`form-input ${ error[item.input_name] ? 'form-error' : '' }`} validate={ (value) => validateInput(item.input_name, value) } name={ item.input_name } type="number" />
                                                                <small className="text-validation text-danger">
                                                                    { error[item.input_name] }
                                                                </small>
                                                            </Fragment>
                                                        );
                                                    case constants.eventInputType.file:
                                                        return (
                                                            <Fragment key={index}>
                                                                <label className="form-label" htmlFor={ item.input_name }>{ item.input_label }</label>
                                                                {/* <Field 
                                                                    id={ item.input_name } 
                                                                    className={`form-input ${ error[item.input_name] ? 'form-error' : '' }`} 
                                                                    validate={ (value) => validateInput(item.input_name, value, true) } 
                                                                    name={ item.input_name } 
                                                                    type="file" 
                                                                /> */}
                                                                <input 
                                                                    type="file" 
                                                                    id={ item.input_name }
                                                                    className={`form-input ${ error[item.input_name] ? 'form-error' : '' }`} 
                                                                    onChange={ (event) => validateInput(item.input_name, event, true) }
                                                                    name={ item.input_name } 
                                                                />
                                                                <small className="text-validation text-danger">
                                                                    { error[item.input_name] }
                                                                </small>
                                                            </Fragment>
                                                        );
                                                }
                                            } )
                                        }

                                        {
                                            pricing.length > 0 ? (
                                                <>
                                                    <label className="form-label" htmlFor="eventPriceId">Jenis Partisipasi</label>
                                                    <Field id="eventPriceId" className={`form-input ${ error.eventPriceId ? 'form-error' : '' }`} name="eventPriceId" as="select" value={ !data.hasOwnProperty('eventPriceId') ? '' : data.eventPriceId } validate={ (value) => validateInput('eventPriceId', value) }>
                                                        {
                                                            pricing.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={ item.id }>
                                                                        { item.event_price_name } - { numberFormatting(item.event_price) }
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </Field>
                                                    <small className="text-validation text-danger">
                                                        { error.eventPriceId }
                                                    </small>
                                                </>
                                            ) : ''
                                        }

                                        <button disabled={ prohibitSubmit } className='form-button' type="submit">
                                            Daftar
                                        </button>
                                    </Form>
                                )
                            }
                    </Formik>
                ) : (
                    <LoadingPage />
                )
            }
        </div>
    );
};

export default connect(mapStateToProps)(EventRegistrationPage);