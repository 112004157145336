import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Init from '../../Init/Init';
import { eventDetail } from '../../Data/Events';
import './EventDetailPage.scss';

import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';
import ServiceFooter from '../../Component/Utilities/ServiceFooter/ServiceFooter';

import { objectIsEmpty } from '../../Util/Util';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        isLogin: state.auth.isLogin
    }
};

const EventDetailPage = (props) => {
    const [ events, setEvents ] = useState({});
    const [ buttonDisabled, setButtonDisabled ] = useState(false);
    const [ desc, setDesc ] = useState('');

    const params = useParams();
    const navigate = useNavigate();

    useEffect( () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
        
        props.checkPage('detail-event');
        
        const { eventId } = params;

        ( async () => {
            const response = await eventDetail(eventId);

             if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                setEvents(response.data);
             }
        } )();

    }, []);

    useEffect( () => {
        const full = eventIsFull();
        const closed = eventIsClosed();

        setButtonDisabled( full || events.hasRegistered || closed );
    }, [events]);

    useEffect( () => {
        let description = '';

        // if( !props.isLogin ) {
        //     description = `<span class="text-danger">**</span>Masuk dengan akun Landack. untuk melakukan pendaftaran event!`;
        // } else 
        if(eventIsFull()) {
            description = `<span class="text-danger">**</span>Kuota peserta sudah habis!`
        } else if( events.hasRegistered ) {
            description = `<span class="text-danger">**</span>Anda sudah terdaftar di acara ini.`
        } else if(eventIsClosed()) {
            description = `<span class="text-danger">**</span>Pendaftaran sudah ditutup!`;
        }
        
        setDesc(description);

    }, [events, props.isLogin]);

    const eventIsFull = () => {
        return events.status === Init.constants.eventStatus.full;
    }

    const eventIsClosed = () => {
        return events.status === Init.constants.eventStatus.closed;
    }

    const registerEvent = () => {
        navigate('/event-registration', {
            state: {
                eventId: params.eventId
            }
        });
        // if(props.isLogin) {
        // } else {
        //     navigate('/menu');
        // }
    }

    return (
        !objectIsEmpty(events) ? (
            <div className='event-detail-page'>
                {/* 
                    1. Event Name
                    2. Event Created Date
                    3. Event Banner
                    4. Event Desc
                    5. Event Time Range
                    6. Registration Deadline
                    7. Register Button
                */}
                <div className="event-header">
                    <h1 className="event-name">
                        { events.event_name }
                    </h1>
                    <div className="event-created-date">
                        { events.created_at }
                    </div>
                </div>
                <div className="event-banner">
                    <img src={ `${process.env.REACT_APP_API_EVENT_STORAGE}${ events.event_banner }` } alt="" />
                </div>
                <div className="event-content">
                    <div className="event-desc" dangerouslySetInnerHTML={{ __html: events?.event_desc }}>
                        
                    </div>
                    <div className="event-detail">
                        <div className="event-timerange">
                            <strong>Waktu Acara:</strong> 
                            <br />
                            { events.event_start } - { events.event_end }
                        </div>
                        {
                            events.participant_limit > 0 ? (
                                <div className="event-participant-limit">
                                    <strong>Batas Peserta:</strong> 
                                    <br />
                                    { events.participant_limit } peserta
                                </div>
                            ) : ('')
                        }
                        <div className="event-registration-deadline">
                            <strong>Batas Waktu Pendaftaran:</strong> 
                            <br />
                            { events.registration_deadline }
                        </div>
                    </div>
                </div>
                <ServiceFooter 
                    label="Daftar Event" 
                    description={ desc } 
                    onClick={ () => registerEvent() }
                    disabled={ buttonDisabled } 
                />
            </div>
        ) : (
            <div className="event-loading-container">
                <LoadingPage />
            </div>
        )
    );
}

export default connect(mapStateToProps)(EventDetailPage);