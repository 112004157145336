import * as actionTypes from './business-entity-types';

const INITIAL_STATE = {
    data: {},
    list: [],
    addOns: {},
    isLoaded: false
}

const businessEntityReducer = (state = INITIAL_STATE, action) => {
    let newData = {};
    let { data, list } = state;
    
    switch(action.type) {
       case actionTypes.BUSINESS_ENTITY_OPTION:
            newData = action.payload.data;

            list = newData.businessEntity;

            for( let index in list ) {
                let item = list[index];
                data[item.business_entity_code] = {
                    code: item.business_entity_code,
                    name: item.business_entity_name,
                    iconPath: item.app_icon_path,
                    priceList: item.prices
                };
            }

            return {
                ...state,
                data,
                list,
                addOns: newData.addOns,
                isLoaded: true
            };
       default: 
            return state;
    }
};

export default businessEntityReducer;