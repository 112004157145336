import React, { useState, useEffect } from 'react';
import './CheckoutDetail.scss';

import Init from '../../../Init/Init';
import { capitalize, numberFormatting, objectIsEmpty, lcFirst } from '../../../Util/Util';
import { Modal } from 'react-bootstrap';

const { FontAwesomeIcon } = Init;

const GeneratedInput = ({ content, onModify }) => {
    if( content === null ) return '';

    const updateInput = ( event, content, value = null ) => {
        const inputText = event.nativeEvent.data;
        

        switch( content.type ) {
            case "phone":
                if( isNaN(inputText) ) {
                    content.ref.current.value = content.ref.current.value.replace(inputText, '');
                }
                
                value = content.ref.current.value;
                break;
            case "yesno":
                content.ref.current = value;

                for(let index in Object.keys(content.options)) {
                    document.getElementsByClassName('option-input')[index].classList.remove('active');
                }
                event.target.classList.add('active');
                break;
            default:
                value = content.ref.current.value;
                break;
        }

        onModify(value);
        // console.log( 'Update input!' );
    }

    switch( content.type ) {
        case 'phone':
        case 'text':
            return (
                <textarea id={ content.id.toLowerCase() } placeholder={ content.hasOwnProperty('placeholder') ? content.placeholder : '' } className='single-input' ref={ content.ref } onChange={ (event) => updateInput( event, content ) } defaultValue={ content.value }></textarea>
            );
        case 'number':
            return (
                <input id={ content.id.toLowerCase() } placeholder={ content.hasOwnProperty('placeholder') ? content.placeholder : '' } className='single-input' type='text' ref={ content.ref } onChange={ (event) => updateInput( event, content ) } defaultValue={ content.value } />
            );
        case 'group':
            return (
                <div className="multi-input">
                    {
                        Object.keys(content.ref).map( (key, index) => {
                            let contentItem = content.ref[key];
                            contentItem.id = key;

                            return (
                                <div key={ index } className="multi-input-item">
                                    <label> { contentItem.label } </label>
                                    <GeneratedInput content={ contentItem } onModify={() => onModify()} />
                                </div>
                            )
                        } )
                    }
                </div>
            );
        case 'yesno':
            return (
                <div id={ content.id } className="options-input">
                    {
                        Object.keys(content.options).map( (key, index) => {
                            let item = content.options[key];
        
                            return (
                                <div key={index} className={`option-input ${ content.value === item ? 'active' : '' }`} onClick={ (event) => updateInput( event, content, item ) }>
                                    { capitalize(key) }
                                </div>
                            )
                        } )
                    }
                </div>
            );
        default: 
            return '';
    }
}

const CheckoutDetail = (props) => {
    const { order, price } = props;
    const [ openModal, setOpenModal ] = useState(false);
    const [ inputs, setInputs ] = useState({});
    /**
     * 
    {
        id: ''
        label: '',
        icon: '',
        ref: null,
        value: '',
        displayOnly: false
    }
     */
    const [ modalContent, setModalContent ] = useState(null);
    const [ confirmDisabled, setConfirmDisabled ] = useState(true);

    const onModify = (value) => {
        const invalidValue = value === null || value === '';
        setConfirmDisabled(invalidValue);
    };

    const saveInput = (id, ref) => {
        if( !confirmDisabled ) {
            props.onModify(id, ref);
            closeInputModal();
        }
    }

    const openInputModal = ( id, content, displayOnly ) => {
        if(!displayOnly) {
            setModalContent({
                id,
                ...content,
                displayOnly
            });
        }
    };

    const closeInputModal = () => {
        setOpenModal( false );
    };

    useEffect( () => {
        setConfirmDisabled(true);
    }, [openModal]);

    useEffect( () => {
        if( modalContent !== null  ) {
            setOpenModal( true );
        }
    }, [ modalContent ]);
    
    useEffect( () => {
        if(!objectIsEmpty(props.inputs)) {
            setInputs({
                ...props.inputs
            });
        }
    }, [props.inputs]);

    return (
        <>
            <Modal className='input-modal' show={ openModal } onHide={ closeInputModal }>
                <Modal.Header closeButton>
                    { modalContent !== null ? (
                        <div className="modal-header-icon">
                            <img src={ modalContent.icon } />
                        </div>
                    ) : '' }
                    { modalContent !== null ? modalContent.label : '' }
                </Modal.Header>
                <Modal.Body>
                    <GeneratedInput content={ modalContent } onModify={ (value) => onModify(value) } />
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-cancel' onClick={ () => closeInputModal() }>
                        Batal
                    </button>
                    <button className="btn-confirm" 
                        disabled={ confirmDisabled } 
                        onClick={ () => saveInput(modalContent.id, modalContent.ref) }
                    >
                        Simpan
                    </button>
                </Modal.Footer>
            </Modal>
            {
                Object.keys(inputs).map( (key, index) => {
                    let item = inputs[key];

                    return (
                        <div key={index} className="box-detail form" onClick={ () => openInputModal( key, item, props.displayOnly ) }>
                            <div className="detail-body">
                                <div className="label">
                                    {
                                        item.hasOwnProperty('icon') && item.icon !== '' ? (
                                            <img src={ item.icon } alt={ item.label } />
                                        ) : ''
                                    }
                                    { item.label }
                                </div>
                                {
                                    props.displayOnly ? (
                                        <div className="input-display">
                                            { item.value }
                                        </div>
                                    ) : (
                                        <div className="input">
                                            <span className="input-display">
                                                { item.value }
                                            </span>
                                            <FontAwesomeIcon icon={ ['fas', 'chevron-right'] } />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                } )
            }
            <div className='box-detail'>
                <div className="detail-header">
                    Rincian Pesanan
                </div>
                <div className="detail-body">
                    { order.map( (item, index) => {
                        return (
                            <div key={index} className="list-item">
                                <div className="item-label">
                                    { item.label }
                                </div>
                                <div className="item-price">
                                    { item.value }
                                </div>
                            </div>
                        )
                    } ) }
                </div>
            </div>
            <div className="box-detail">
                <div className="detail-header">
                    Rincian Harga
                </div>
                <div className="detail-body">
                    { price.map( (item, index) => {
                        return (
                            <div key={index} className="list-item">
                                <div className="item-label">
                                    { item.label }
                                </div>
                                <div className="item-price">
                                    { numberFormatting(item.value) }
                                </div>
                            </div>
                        )
                    } ) }
                </div>
            </div>
        </>
    );
}

CheckoutDetail.defaultProps = {
    displayOnly: false
}

export default CheckoutDetail;