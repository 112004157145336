import React, { useState, useEffect } from 'react';

import { numberFormatting } from '../../../Util/Util';
import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import './BusinessEntityList.scss';
import ServiceHeader from '../../Utilities/ServiceHeader/ServiceHeader';
import ServiceFooter from '../../Utilities/ServiceFooter/ServiceFooter';

const BusinessEntityList = (props) => {
    const gaEventTracker = useAnalyticsEventTracker('Pendirian Badan Usaha');
    const { data } = props.serviceData;
    const service = data;

    const [ entityCode, setEntityCode ] = useState(service.code);
    const [ buttonDisabled, setButtonDisabled ] = useState(true);

    const chooseEntity = () => {
        props.choose( entityCode );
    }

    useEffect( () => {
        setButtonDisabled( entityCode === '' );
    }, [ entityCode ]);

    return (
        <div className="business-entity-container">
            <ServiceHeader name={ service.name } iconPath={ `${process.env.REACT_APP_API_BUSINESS_ENTITY_STORAGE}${service.iconPath}` } description={ service.name } />
            <div className="business-entity-subtitle">
                Pilih Paket Layanan
            </div>
            <div className="business-entity-list">
                    {
                        service.hasOwnProperty('priceList') && 
                        service.priceList.map((value, key) => {
                            return  (
                                <div key={key} className={`business-entity-item ${ entityCode === value.business_entity_code ? 'active' : '' }`} onClick={ () => setEntityCode(value.business_entity_code) }>
                                    <div className="additional-price">
                                        {/* 
                                            price-label
                                            order-btn
                                        */}
                                        <div className="price-info">
                                            <span className="price-label">
                                                { value.business_entity_name }
                                            </span>
                                            <span className="price-number">
                                                {
                                                    value.hasOwnProperty('markup_price') && value.markup_price > 0 ? (
                                                        <span className="real">
                                                            { numberFormatting(value.markup_price) }
                                                        </span>
                                                    ) : ''
                                                }
                                                { numberFormatting(value.price) }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
            </div>
            <ServiceFooter 
                label="Pesan Sekarang" 
                description={ `
                    <span class='text-danger'>**</span>Dengan melanjutkan pembayaran, sobat sudah menyetujui syarat dan ketentuan dan kebijakan privasi Landack.
                ` } 
                onClick={ () => chooseEntity() }
                disabled={ buttonDisabled } 
            />
        </div>
    );
}

export default BusinessEntityList;