import * as actionTypes from './navbar-types';
import Routes from '../../Routing/Routing';
import { objectIsEmpty } from '../../Util/Util';

const INITIAL_STATE = {
    pageId: '',
    pageTitle: '',
    show: true,
    navLink: {},
    goBack: -1
}

const navbarReducer = (state = INITIAL_STATE, action) => {
    let { pageId, pageTitle, navLink, show, goBack } = state;

    switch(action.type) {
        case actionTypes.INIT_NAVBAR:
            var routes = Routes();
            
            for( let index in routes ) {
                let item = routes[index];
                let id = item.name.toLowerCase().split(" ").join("-");
    
                navLink[id] = item;
            }

            break;
        case actionTypes.TOGGLE_SHOW:
            pageId = action.payload.pageId;

            show = navLink.hasOwnProperty(pageId) ? navLink[pageId].showNav : false;
            pageTitle = navLink.hasOwnProperty(pageId) ? navLink[pageId].name : '';
            goBack = -1;

            break;
        case actionTypes.OVERRIDE_BACK_FUNCTION:
            goBack = action.payload.goBack;

            break;
        default: 
            break;
    }

    return {
        ...state,
        pageId,
        pageTitle,
        navLink,
        show,
        goBack
    };
};

export default navbarReducer;