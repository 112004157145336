import React, { useState, useEffect, useRef } from 'react';
import './ServiceOption.scss';

import { Modal } from 'react-bootstrap';

import { constructMinuteString } from '../../../Util/Util';

const DurationCustomSelect = (props) => {
    const {
        qtyRef,
        portion,
        unit
    } = props;

    const [ customOption, setCustomOption ] = useState({
        hour: [],
        minute: []
    });
    const [ moreThanHour, setMoreThanHour ] = useState(true); 
    const hourRef = useRef('');
    const minuteRef = useRef('');
    const defaultHour = "60";
    const defaultMinute = "0";

    useEffect( () => {
        if( !qtyRef.current.hasOwnProperty('value') ) {
            qtyRef.current = {
                value: ''
            };
        }

        let customOptions = {
            hour: [],
            minute: []
        };

        for( let i = 0 ; i <= 3 ; i++ ) {
            customOptions.minute.push(i);
        }

        for( let i = 0 ; i <= 6 ; i++ ) {
            customOptions.hour.push(i);
        }

        setCustomOption({...customOptions});
    }, [ moreThanHour ]);

    useEffect( () => {
        if( hourRef.current.value !== '' && minuteRef.current.value !== '' ) {
            updateMinutes();
        }

    }, [ hourRef.current, minuteRef.current ]);

    const updateMinutes = (event) => {
        const hour = hourRef.current;
        const minute = minuteRef.current;

        const totalMinute = parseInt(hour.value) + parseInt(minute.value);

        qtyRef.current.value = (totalMinute > 0 ? totalMinute : portion) / portion;

        setMoreThanHour( totalMinute >= 60 )
    }

    return (
        <>
            <select ref={ hourRef } defaultValue={ defaultHour } className='qty-select hour' onChange={ (event) => updateMinutes(event) }>
                {
                    customOption.hour.map( (item, index) => {
                        return (
                            <option key={index} value={ item * 60 } selected={ item * 60 === 60 ? 'selected' : '' }>
                                { item }
                            </option>
                        );
                    } )
                }
            </select>
            <span className='qty-unit'> Jam </span>
            <select ref={ minuteRef } defaultValue={ defaultMinute } className='qty-select minute' onChange={ (event) => updateMinutes(event) }>
                {
                    customOption.minute.map( (item, index) => {
                        return (!moreThanHour && item * portion > 0 ) || moreThanHour ? (
                            <option key={index} value={ item * portion }>
                                { item * portion }
                            </option>
                        ) : '';
                    } )
                }
            </select>
            <span className='qty-unit'> Menit </span>
        </>
    );
}

const GeneralCustomSelect = (props) => {
    const {
        qtyRef,
        portion,
        unit
    } = props;
    
    const [ customOption, setCustomOption ] = useState([]);

    useEffect( () => {
        let customOptions = [];

        for( let i = 1 ; i <= 100 ; i++ ) {
            customOptions.push(i);
        }

        setCustomOption(customOptions);
    }, []);

    return (
        <>
            <select className='qty-select' ref={ qtyRef }>
                {
                    customOption.map( (item, index) => {
                        return (
                            <option key={index} value={ item * portion }>
                                { item * portion }
                            </option>
                        );
                    } )
                }
            </select>
            <span className='qty-unit'> { unit } </span>
        </>
    );
}

const GeneralCustomInput = (props) => {
    const {
        qtyRef,
        portion,
        unit
    } = props;

    const changeValue = (event) => {
        const inputData = event.nativeEvent.data;
        if( isNaN(inputData) ) {
            qtyRef.current.value = qtyRef.current.value.replace(inputData, '');
            console.log([qtyRef.current.value, inputData]);
        }
    }

    return (
        <>
            <input className='qty-input' ref={ qtyRef } onChange={ (event) => changeValue(event) } /> { portion > 1 ? ` x ${portion}` : '' }
            <span className='qty-unit'> { unit } </span>
        </>
    );
}

const CustomOption = (props) => {
    const {
        opt,
        chooseQty,
        qty,
        portion,
        unit
    } = props;

    return unit === 'menit' ? (
        <div className={`qty-item ${ opt === 4 ? 'active' : '' }`} onClick={ () => chooseQty(4) }>
            Custom { qty !== null ? `: ${ constructMinuteString( qty, portion ) }` : '' }
        </div>
    ) : (
        <div className={`qty-item ${ opt === 4 ? 'active' : '' }`} onClick={ () => chooseQty(4) }>
            Custom { qty !== null ? `: ${qty * portion} ${unit}` : '' }
        </div>
    );
}

const ServiceOption = (props) => {
    const [ opt, setOpt ] = useState(null);
    const [ options, setOptions] = useState([]);
    const [ service, setService ] = useState({});
    const [ showModal, setShowModal ] = useState(false);
    const customQtyRef = useRef('');

    const chooseQty = (k) => {
        if(k === 4) {
            openModal();
        } else {
            props.onChoose(k);
        }
    };

    useEffect( () => {
        setService(props.service);
    }, [props.service]);

    useEffect( () => {
        setOpt( props.option );
    }, [ props.option ]);

    useEffect( () => {
        setOptions( props.options );
    }, [ props.options ]);

    const closeModal = () => {
        setShowModal(false);
    }

    const openModal = () => {
        setShowModal(true);
    }

    const saveCustomQty = () => {
        const qty = customQtyRef.current.value;
        props.onChoose(4, parseInt(qty));
        closeModal();
    }

    return (
        <>
            <Modal show={ showModal } onHide={ closeModal } className="custom-qty-modal">
                <Modal.Header className='modal-title' closeButton>
                    Pilih Jumlah { service.unit }
                </Modal.Header>
                <Modal.Body>
                    {
                        service.unit === 'menit' ? (
                            <DurationCustomSelect qtyRef={ customQtyRef } portion={ service.portion } unit={ service.unit } />
                        ) : (
                            // <GeneralCustomSelect qtyRef={ customQtyRef } portion={ service.portion } unit={ service.unit } />
                            <GeneralCustomInput qtyRef={ customQtyRef } portion={ service.portion } unit={ service.unit } />
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-cancel' onClick={ () => closeModal() }>
                        Batal
                    </button>
                    <button className="btn-confirm" onClick={ () => saveCustomQty() }>
                        Simpan
                    </button>
                </Modal.Footer>
            </Modal>
            <div className="service-opt">
                {
                    options.map( (v, k) => {
                        return k !== 4 ? (
                            <div key={k} className={`qty-item ${ opt === k ? 'active' : '' }`} onClick={ () => chooseQty(k) }>
                                { `${v * service.portion} ${service.unit}` }
                            </div>
                        ) : (
                            <CustomOption key={k}
                                opt={ opt }
                                chooseQty={ chooseQty }
                                qty={ v }
                                portion={ service.portion }
                                unit={ service.unit }
                            />
                        )
                    } )
                }
            </div>
        </>
    );
}

export default ServiceOption;