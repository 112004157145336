import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import './ProfileDetailPage.scss';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';

import { connect } from 'react-redux';
import { authenticate } from '../../Redux/Auth/auth-action';

import authAPI from '../../Data/Auth';
import { Toast } from 'react-bootstrap';

const { saveProfile } = authAPI;

const mapStateToProps = (state) => {
    return {
        customer: state.auth.data,
        dataLoaded: state.auth.isLoaded
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        authenticate: () => dispatch(authenticate()),
    }
}

const ProfileDetailPage = (props) => {
    const [ data, setData ] = useState(null);
    const [ prohibitSubmit, setProhibitSubmit ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(true);
    const { authenticate } = props;

    const [ error, setError ] = useState({});
    const [toastContent, setToastContent] = useState({
        message: '',
        show: false
    });

    useEffect( () => {
        window.scroll({
            top: 0,
            behaviour: 'smooth'
        });

        props.checkPage('data-diri');
    }, []);

    useEffect( () => {
        if( props.customer.id ) {
            setData({
                customer_fullname: props.customer.customer_fullname,
                birthday: props.customer.birthday,
                gender: props.customer.gender
            });
        } else {
            setData(null);
        }
    }, [ props.customer ]);

    useEffect( () => {
        let formFilled = true;
        
        if(data !== null) {
            setIsLoading( false );

            for(let key in data) {
                if(data[key] === '' || data[key] === null) {
                    formFilled = false;
                    break;
                }
            }

            setProhibitSubmit( !formFilled );
        } else {
            setIsLoading( true );
        }

    }, [ data ]);

    const save = (values) => {
        console.log("Validate on Submit");
        
        ( async () => {
            const response = await saveProfile(values);

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                authenticate();
                setToastContent({
                    message: response.message,
                    show: true
                });
                setError({});
            } else {
                const errorMessages = response.data;
                const errorData = {};

                for(let index in errorMessages) {
                    let item = errorMessages[index];

                    errorData[item.field] = item.message;
                }

                setError({
                    ...errorData
                });
            }
        } )();
    };

    const validateInput = (name, value) => {
        const dataCopy = {...data};
        
        if( dataCopy.hasOwnProperty(name) && dataCopy[name] !== value ) {
            console.log("Validate on Change");
            dataCopy[name] = value;
    
            setData({
                ...dataCopy
            });
        }
    };

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    };
    
    return (
        <div className="profile-detail-page">
            {/* 
                Profile Detail Page:
                0.1 Toast
                1. Form:
                    - Fullname
                    - Birthday
                    - Gender
                2. Submit Button
            */}
            <Toast 
                className='success-toast'
                show={toastContent.show}
                // autohide?: boolean;
                // animation?: boolean;
                // delay?: number;
                // onClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
                // show?: boolean;
                // transition?: TransitionComponent;
                // bg={''}
            >
                <Toast.Body className='success-toast-body'>
                    <div className="message">
                        { toastContent.message }
                    </div>
                    <button className='btn-close' onClick={closeToast}>
                    </button>
                </Toast.Body>
            </Toast>
            {
                !isLoading ? (
                    <Formik
                        initialValues={ data }
                        onSubmit={ (values) => save(values) }
                        validateOnChange={ false }
                    >
                        <Form className="page-form">
                            <label className="form-label" htmlFor="customer_fullname">Nama Lengkap</label>
                            <Field className={`form-input ${ error.customer_username ? 'form-error' : '' }`} validate={ (value) => validateInput("customer_fullname", value) } name="customer_fullname" type="text" placeholder={"Masukkan nama lengkap"} />
                            <small className="text-validation text-danger">
                                { error.customer_fullname }
                            </small>
                            
                            <label className="form-label" htmlFor="birthday">Tanggal Lahir</label>
                            <Field className={`form-input ${ error.customer_username ? 'form-error' : '' }`} validate={ (value) => validateInput("birthday", value) } name="birthday" type="date" />
                            <small className="text-validation text-danger">
                                { error.birthday }
                            </small>
                            
                            <label className="form-label" htmlFor="gender">Jenis Kelamin</label>
                            <Field className={`form-input ${ error.customer_username ? 'form-error' : '' }`} validate={ (value) => validateInput("gender", value) } name="gender" as="select">
                                <option value="M">Laki-laki</option>
                                <option value="F">Perempuan</option>
                            </Field>
                            <small className="text-validation text-danger">
                                { error.gender }
                            </small>

                            <button disabled={ prohibitSubmit } className='form-button' type="submit">
                                Ubah
                            </button>
                        </Form>
                    </Formik>
                ) : (
                    <LoadingPage />
                )
            }
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailPage);