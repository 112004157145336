import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { isMobile } from 'react-device-detect';
import appleConfig from './Apple';

var Init = {};

// Constants
Init.constants = {
    serviceType: {
        legalService: 1,
        package: 2,
        businessEntity: 3,
        event: 4
    },
    paymentService: {
        purwantara: 1,
        cashbac: 2
    },
    transactionStatus: {
        waitingForPayment: 0,
        onQueue: 1,
        onGoing: 2,
        completed: 3,
        rejected: 4,
        cancelled: 5
    },
    eventStatus: {
        inactive: 0,
        oncoming: 1,
        onGoing: 2,
        full: 3,
        finished: 4,
        closed: 5
    },
    eventInputType: {
        text: 1,
        textarea: 2,
        number: 3,
        file: 4
    },
    eventParticipantStatus: {
        hasNotPaid: 0,
        hasPaid: 1,
        rejected: 2
    },
    flags: {
        yesNo: {
            yes: 1,
            no: 0
        }
    },
    text: {
        waitingForPayment: 'Menunggu Pembayaran',
        onQueue: 'Menunggu Antrian',
        onGoing: 'Sedang Berlangsung',
        completed: 'Selesai',
        rejected: 'Ditolak',
        cancelled: 'Dibatalkan',
        yes: 'Yes',
        no: 'No'
    }
};

// Basic config
Init.config = {
    carousel: {
        swipeable: true,
        draggable: true,
        showDots: true,
        responsive: {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 3 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        },
        ssr: false, // means to render carousel on server-side.
        infinite: true,
        autoPlay: false,
        autoPlaySpeed: 99999,
        keyBoardControl: true,
        customTransition: "all .5",
        transitionDuration: 500,
        removeArrowOnDeviceType: [
            // "tablet", 
            // "mobile", 
            // "web"
        ],
        deviceType: "web",
        partialVisible: false,
        centerMode: false,
        containerClass: "carousel-container",
        dotListClass: "custom-dot-list-style",
        itemClass: "carousel-item-padding-40-px"
    },
    customImageFilter: {
        none: [
            1, 0, 0, 0, 0,
            0, 1, 0, 0, 0,
            0, 0, 1, 0, 0,
            0, 0, 0, 1, 0,
        ],
        grayscale: [
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0.85, 0, 0, 0, 0,
            0, 0, 0, 1, 0,
        ]
    },
    imageFolder: "/assets/images/",
    logoFolder: "/assets/logo/",
    iconFolder: "/assets/icons/",
    sampleFolder: "/assets/sample/",
    headerFolder: "/assets/images/header/",
    isMobile: isMobile,
    apple: appleConfig
}


Init.config.imageLogo = `${Init.config.logoFolder}logo.png`;
Init.config.imageLogoAlt = `${Init.config.logoFolder}logo-full.png`;
Init.config.imageLogoText = `${Init.config.logoFolder}logo-text.png`;

Init.config.sectionAssets = {
    header: `${Init.config.imageFolder}header-1.png`,
    whyUs: {
        simple: `${Init.config.imageFolder}why-us-simple.png`,
        affordable: `${Init.config.imageFolder}why-us-affordable-price.png`,
        fast: `${Init.config.imageFolder}why-us-fast.png`,
        accurate: `${Init.config.imageFolder}why-us-accurate.png`,
        prp: `${Init.config.imageFolder}why-us-prp.png`,
        professional: `${Init.config.imageFolder}pricing-ldd.png`
    },
    individualPricing: {
        consultation: `${Init.config.imageFolder}pricing-chat-consultation.png`,
        agreement: `${Init.config.imageFolder}pricing-agreement-docs.png`,
        nda: `${Init.config.imageFolder}pricing-nda.png`,
        somation: `${Init.config.imageFolder}pricing-somation.png`,
        somationAns: `${Init.config.imageFolder}pricing-somation-answer.png`,
        ldd: `${Init.config.imageFolder}pricing-ldd.png`,
        docReview: `${Init.config.imageFolder}pricing-document-review.png`,
        brandRegistration: `${Init.config.imageFolder}pricing-ldd.png`
    },
    corporatePricing: {
        bronze: `${Init.config.imageFolder}corporate-pricing-bronze.png`,
        silver: `${Init.config.imageFolder}corporate-pricing-silver.png`,
        gold: `${Init.config.imageFolder}corporate-pricing-gold.png`
    },
    businessEntity: {
        main: `${Init.config.imageFolder}business-entity.png`,
        confused: `${Init.config.imageFolder}confused.png`
    }
}

Init.config.imageIcon = `${Init.config.iconFolder }favicon.png`;
Init.config.whatsappIcon = `${Init.config.iconFolder}whatsapp.png`;
Init.config.puzzleIcon = `${Init.config.iconFolder}puzzle.png`;
Init.config.ctaButton = `${Init.config.iconFolder}cta-button.svg`;
Init.config.activityIcon = `${Init.config.iconFolder}activity.png`;
Init.config.justiceIcon = `${Init.config.iconFolder}justice.png`;

Init.config.calendarIcon = `${Init.config.iconFolder}calendar.png`;
Init.config.calendarWithArrowIcon = `${Init.config.iconFolder}calendar-with-arrow.png`;
Init.config.clockIcon = `${Init.config.iconFolder}clock.png`;
Init.config.qtyIcon = `${Init.config.iconFolder}qty.png`;
Init.config.userIcon = `${Init.config.iconFolder}user.png`;
Init.config.settingIcon = `${Init.config.iconFolder}settings.png`;
Init.config.csIcon = `${Init.config.iconFolder}customer-service.png`;
Init.config.faqIcon = `${Init.config.iconFolder}help.png`;
Init.config.infoIcon = `${Init.config.iconFolder}info.png`;
Init.config.privacyIcon = `${Init.config.iconFolder}protection.png`;
Init.config.termsIcon = `${Init.config.iconFolder}book-open.png`;
Init.config.logoutIcon = `${Init.config.iconFolder}logout.png`;
Init.config.googleIcon = `${Init.config.iconFolder}google-logo.png`;
Init.config.appleIcon = `${Init.config.iconFolder}apple-logo.png`;

// Extensions
library.add(fab, fas, far);
Init.FontAwesomeIcon = FontAwesomeIcon;

export default Init;