import React, { useState, useEffect } from 'react';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import Init from '../../../Init/Init';
import './Header.scss';

import { connect } from 'react-redux';
import bannerActions from '../../../Redux/Banner/banner-action';

const { loadBanner } = bannerActions;

const mapStateToProps = (state) => {
    return {
        banner: state.banner.data
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        load: () => dispatch(loadBanner())
    }
}

const Header = (props) => {
    let config = Init.config;
    let { carousel } = config;

    const [ bannerList, setBannerList ] = useState([]);

    // useEffect( () => {
    //     props.load();
    // }, []);

    useEffect( () => {
        setBannerList( props.banner );
    }, [ props.banner ]);

    /**
     * Carousels
     */
    let carouselConfig = carousel;

    carouselConfig.containerClass = "header-container";
    carouselConfig.itemClass = "header-item-holder";
    carouselConfig.dotListClass = "header-dots";
    carouselConfig.removeArrowOnDeviceType = [
        'desktop',
        'tablet',
        'mobileTablet',
        'mobile'
    ];
    carouselConfig.autoPlay = true;
    carouselConfig.autoPlaySpeed = 5000;
    carouselConfig.partialVisible = false;
    carouselConfig.showDots = true;
    carouselConfig.infinite = true;
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1200, min: 900 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 900, min: 650 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 650, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        }
    }

   return (
        <Carousel
            swipeable={carouselConfig.swipeable}
            draggable={carouselConfig.draggable}
            showDots={carouselConfig.showDots}
            responsive={carouselConfig.responsive}
            ssr={carouselConfig.ssr} // means to render carousel on server-side.
            infinite={carouselConfig.infinite}
            autoPlay={carouselConfig.autoPlay}
            autoPlaySpeed={carouselConfig.autoPlaySpeed}
            keyBoardControl={carouselConfig.keyBoardControl}
            transitionDuration={carouselConfig.transitionDuration}
            removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
            deviceType={carouselConfig.deviceType}
            containerClass={carouselConfig.containerClass}
            dotListClass={carouselConfig.dotListClass}
            itemClass={carouselConfig.itemClass}
        >
            {
                bannerList.length > 0 ? (
                    bannerList.map((v, k) => {
                        return (
                            <div key={k} id={`header-${k}`} className="header">
                                <img className={`header-content`} src={ `${process.env.REACT_APP_API_BANNER_STORAGE}${v}` } />
                            </div>
                        );
                    })
                ) : (
                    <LoadingPage />
                )
            }
        </Carousel>
    );
}



export default connect(mapStateToProps, mapDispatchToProps)(Header);