import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { numberFormatting } from '../../../Util/Util';
import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import './EventList.scss';
import EventItem from '../EventItem/EventItem';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

const EventList = (props) => {
    const gaEventTracker = useAnalyticsEventTracker('Pendaftaran Acara');
    const { data } = props;
    // const data = [];

    const chooseEntity = () => {
        // props.choose( entityCode );
    }

    return (
        <div className="event-container">
            <div className="event-list">
                    {
                        data.length > 0 ? (
                            data.map((item, key) => {
                                return  (
                                    <Link to={`/event/${item.id}`} key={key} className='event-choice'>
                                        <EventItem
                                            eventBanner={ item.event_banner }
                                            eventName={ item.event_name }
                                            eventDesc={ item.event_desc }
                                        /> 
                                    </Link>
                                )
                            })
                        ) : (
                            <span className='event-empty-text'> Tidak ada data </span>
                        )
                    }
            </div>
        </div>
    );
}

export default EventList;