import React, {Component, useContext, useEffect} from 'react';
import './Homepage.scss';

import Header from '../../Component/PagesComponent/Header/Header';
import PricingInfo from '../../Data/Pricing';
import BusinessEntity from '../../Component/PagesComponent/BusinessEntity/BusinessEntity';

import ReactGA from 'react-ga';
import LegalService from '../../Component/PagesComponent/LegalService/LegalService';
import CorporatePackage from '../../Component/PagesComponent/CorporatePackageList/CorporatePackageList';
import Event from '../../Component/PagesComponent/Events/Events';
import WhatsappButton from '../../Component/PagesComponent/WhatsappButton/WhatsappButton';
import NavigationContext from '../../Context/NavigationContext';

const Homepage = (props) => {
    const pricing = PricingInfo();
    const context = useContext(NavigationContext);

    useEffect( () => {
        window.scroll({
            top: 0,
            behaviour: 'smooth'
        });

        props.checkPage('beranda');
    }, []);

    return (
        <div className="homepage">
            <Header />
            <LegalService />
            <BusinessEntity />
            <CorporatePackage corporatePackageData={ pricing.corporate } />
            <Event />
            <WhatsappButton />
        </div>
    );
};

export default Homepage;