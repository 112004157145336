import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';

import './TopBar.scss';

import Init from '../../../Init/Init';
import { connect } from 'react-redux';
import { getGoogleOauthURL, objectIsEmpty } from '../../../Util/Util';

const mapStateToProps = (state) => {
    return {
        title: state.navbar.pageTitle,
        showNav: state.navbar.show,
        goBack: state.navbar.goBack,
        identity: state.auth.data,
        isLogin: state.auth.isLogin
    }
}

const { FontAwesomeIcon } = Init;

function TopBar(props) {
    let logoPath = Init.config.imageLogoAlt;
    const navigate = useNavigate();

    const [ showNav, setShowNav ] = useState(true);
    const [ identity, setIdentity ] = useState({});

    useEffect( () => {
        setShowNav(props.showNav);
    }, [props.showNav]);

    useEffect( () => {
        setIdentity(props.identity);
    }, [props.isLogin, props.identity]);

    const goBack = () => {
        if ( props.goBack !== -1 ) {
            props.goBack();
        } else {
            navigate( -1, {
                replace: true
            } );
        }
    };

    const clickUserIcon = () => {
        navigate("/menu");
    }

    return showNav ? (
        <Navbar variant='dark' id="top-bar" expand="xl" className={`custom-navbar-bg ${ showNav ? '' : 'd-none'}`}>
            <Navbar.Brand as={Link} to={"/"}>
                <img className="nav-logo" src={logoPath} alt="Logo" />
            </Navbar.Brand>
            <div className="top-right-menu">
                {/* <div className="top-menu-item">
                    <FontAwesomeIcon className='top-menu-icon' icon={['fas', 'bell']} />
                </div> */}
                <div className="top-menu-item" onClick={ () => clickUserIcon() }>
                    {
                        identity !== null && !objectIsEmpty(identity) ? (
                            <img src={ identity.profile_photo } className='top-menu-profile-picture' />
                        ) : (
                            <FontAwesomeIcon className='top-menu-icon' icon={['fas', 'user-circle']} />
                        )
                    }
                </div>
            </div>
        </Navbar>
    ) : (
        <div className={`topbar ${props.customClass}`}>
            <FontAwesomeIcon className="back-icon" icon={['fas', 'arrow-left']} onClick={ () => goBack() } /> { props.title }
        </div>
    );
}

TopBar.defaultProps = {
    title: 'Pages',
    customClass: '',
    backUrl: -1
}

export default connect(mapStateToProps)(TopBar);