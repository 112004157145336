import * as actionTypes from './banner-types';

const INITIAL_STATE = {
    data: []
}

const bannerReducer = (state = INITIAL_STATE, action) => {
    let { data } = state;
    
    switch(action.type) {
       case actionTypes.BANNER_LIST:
            data = action.payload.data;

            return {
                ...state,
                data
            };
       default: 
            return state;
    }
};

export default bannerReducer;