import { combineReducers } from 'redux';

import navbarReducer from './Navbar/navbar-reducer';
import bannerReducer from './Banner/banner-reducer';
import legalServiceReducer from './LegalService/legal-service-reducer';
import packageReducer from './Package/package-reducer';
import businessEntityReducer from './BusinessEntity/business-entity-reducer';
import authReducer from './Auth/auth-reducer';
import eventReducer from './Event/event-reducer';

const rootReducer = combineReducers({
    /**
     * Article
     * Legal Services
     * Business Entity
     * Package Order
     */
    auth: authReducer,
    navbar: navbarReducer,
    legalService: legalServiceReducer,
    package: packageReducer,
    businessEntity: businessEntityReducer,
    banner: bannerReducer,
    event: eventReducer
});

export default rootReducer;