import { responseFormat, getFormattedDatetime } from '../Util/Util';
import api from './API';
import Cookie from 'universal-cookie';

const cookies = new Cookie();

const basePath = 'event';
const pathList = {
    list: `/`,
    detail: `/:param`,
    form: `/:param/form`,
    registration: `/:param/register`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

export const eventList = async () => {
    try {
        const apiPath = pathList.list;

        const response = await api.getCall(apiPath);

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
            return responseFormat(response.errorCode, 'Fetch events success', data);
        } else {
            throw Error("Failed to get events!");
        }

    } catch(e) {
        console.log(e.toString());

        return responseFormat(-1, 'Failed to get events!', {});
    }
};

export const eventDetail = async (id) => {
    try {
        const apiPath = pathList.detail;
        const token = cookies.get('authToken');
        const response = await api.getCall(apiPath, id, {}, token !== null ? token : '');

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
            return responseFormat(0, 'Data event berhasil diambil!', data);
        }else {
            throw Error("Gagal mendapatkan data event!");
        }

    } catch(e) {
        return responseFormat(-1, e.toString(), {});
    }
};

export const eventForm = async (id) => {
    try {
        const apiPath = pathList.form;
        const response = await api.getCall(apiPath, id);

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
            return responseFormat(0, 'Data event berhasil diambil!', data);
        }else {
            throw Error("Gagal mendapatkan data event!");
        }

    } catch(e) {
        console.log(e.toString());

        return responseFormat(-1, e.toString(), {});
    }
};

export const eventRegistration = async (id, priceId, inputData, name, email, whatsappNo) => {
    try {
        const apiPath = pathList.registration;
        const token = cookies.get('authToken');

        const response = await api.postCall(apiPath, id, {
            input_data: inputData,
            event_price_id: priceId,
            is_web: 1,
            name,
            email,
            whatsapp_no: whatsappNo
        }, token);

        return response;
    } catch(e) {
        console.log(e.toString());

        return responseFormat(-1, e.toString(), {});
    }
}