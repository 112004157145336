import React from 'react';
import './ServiceFooter.scss';

function ServiceFooter(props) {
    const onClick = () => {
        if( !props.disabled ) {
            props.onClick();
        }
    }

    return (
        <div className="service-footer">
            {
                props.description !== '' ? (
                    <div className="notes" dangerouslySetInnerHTML={{__html: props.description}}></div>
                ) : ''
            }
            <button className={`btn-choose ${props.disabled ? 'disabled' : ''}`} onClick={ () => onClick() }>
                { props.label }
            </button>
        </div>
    );
}

ServiceFooter.defaultProps = {
    label: '',
    description: '',
    disabled: false
}

export default ServiceFooter;