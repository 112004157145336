import React, { useEffect, useState, Fragment, useRef } from 'react';

import Init from '../../../Init/Init';
import './CorporatePackageOptions.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import { numberFormatting, buildWaLink } from '../../../Util/Util';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

let { FontAwesomeIcon } = Init;

const CorporatePackageItem = (props) => {
    return (
        <div className={"corporate-package-item" + (props.hasOwnProperty('isRecommended') && props.isRecommended ? ' is-recommended' : '') + (props.hasOwnProperty('isBestSeller') && props.isBestSeller ? ' is-best-seller' : '')}>
            <div className="corporate-package-item-icon">
                <img src={`${process.env.REACT_APP_API_PACKAGE_STORAGE}${props.iconPath}`} alt="Icon Layanan" />
            </div>
            <h3 className="corporate-package-item-title">
                { props.name }
            </h3>
            <div className="corporate-package-item-desc-list" dangerouslySetInnerHTML={ { __html: props.packageDescription } }>

            </div>
            {/* <ul className='corporate-package-item-desc-list'>
                {
                    props.descriptionList.map( (point, index) => {
                        let wording = point.service_name;

                        let item = props.descriptionList[index];

                        let servicePortion = `${ item.portion > 1 ? `${item.frequency} x ${item.portion} ${item.unit}` : `${item.frequency} ${item.unit}`}`;
                        servicePortion = item.frequency * item.portion > 0 ? `(${servicePortion})` : 'Tanpa Batas';

                        wording = `${ point.service_name } ${ servicePortion }`;

                        return (
                            <li key={index} className="corporate-package-item-desc">
                                <FontAwesomeIcon className='desc-icon' icon={['fas', 'check-circle']} /> { wording }
                            </li>
                        );
                    } )
                }
            </ul> */}
            <h5 className='corporate-package-item-label'>
                Harga: {
                        props.priceObj.realPrice > 0 ? (
                            <span className='corporate-package-item-real-price'>{ numberFormatting(props.priceObj.realPrice) } </span>
                        ) : ( '' )
                    }
            </h5>
            <span className='corporate-package-item-price'>
                <span className=' corporate-package-item-discount-price'>
                    { numberFormatting(props.priceObj.discountPrice) } 
                </span> / bulan 
            </span>
            {
                props.priceObj.realPrice > 0 ? (
                    <span className='corporate-package-item-price corporate-package-item-save-percentage'>
                        (Hemat sampai { props.priceObj.savePercentage })
                    </span>
                ) : ( '' )
            }
        </div>
    );
}

const CorporatePackageList = (props) => {
    const [ packageOpt, setPackageOpt ] = useState([]);
    const [ packageIndex, setPackageIndex ] = useState(0);

    const gaEventCategory = 'Paket Korporat';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    const carouselRef = useRef(null);

    useEffect(() => {
        setPackageOpt( props.prices );
    }, [ props.prices ]);

    useEffect(() => {
        if(packageOpt.length > 0 && carouselRef && carouselRef.current) {
            for( let index in packageOpt ) {
                let item = packageOpt[index];

                if(item.code === props.selectedPackage) {
                    carouselRef.current.goToSlide(index);
                    break;
                }
            }
        }
    }, [packageOpt, carouselRef.current]);

    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "corporate-package-option";
    carouselConfig.itemClass = "package-item-holder";
    carouselConfig.showDots = false;
    carouselConfig.infinite = false;
    carouselConfig.partialVisible = true;
    carouselConfig.autoPlay = false;
    carouselConfig.dotListClass = 'package-item-dot-list';
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1550 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1550, min: 1200 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobileTablet: {
            breakpoint: { max: 1200, min: 800 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        }
    }

    carouselConfig.removeArrowOnDeviceType = [
        // 'desktop',
        // 'tablet',
        // 'mobileTablet',
        // 'mobile'
    ];

    const changeSlide = (previousSlide, { currentSlide, onMove }) => {
        if( packageOpt.length > 0 && packageOpt.hasOwnProperty(currentSlide) ) {
            setPackageIndex(currentSlide);
        }
    }

    return (
        <>
            {
                packageOpt.length > 0 && packageOpt.hasOwnProperty( packageIndex ) ? (
                    <Carousel
                        swipeable={carouselConfig.swipeable}
                        draggable={carouselConfig.draggable}
                        showDots={carouselConfig.showDots}
                        responsive={carouselConfig.responsive}
                        ssr={carouselConfig.ssr} // means to render carousel on server-side.
                        infinite={carouselConfig.infinite}
                        autoPlay={carouselConfig.autoPlay}
                        autoPlaySpeed={carouselConfig.autoPlaySpeed}
                        keyBoardControl={carouselConfig.keyBoardControl}
                        transitionDuration={carouselConfig.transitionDuration}
                        removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                        deviceType={carouselConfig.deviceType}
                        containerClass={carouselConfig.containerClass}
                        dotListClass={carouselConfig.dotListClass}
                        itemClass={carouselConfig.itemClass}
                        afterChange={ changeSlide }
                        ref={ carouselRef }
                        // partialVisbile={carouselConfig.partialVisible}
                    >
                        {
                            packageOpt.map( (v, k) => {
                                return (
                                    <CorporatePackageItem 
                                        key={k}
                                        name={ v.name }
                                        isRecommended={ v.isRecommended }
                                        isBestSeller={ v.isBestSeller }
                                        iconPath={ v.iconPath }
                                        priceObj={ v.priceObj }
                                        descriptionList={ v.descriptionList }
                                        packageDescription={ v.packageDescription }
                                    />
                                );
                            } )
                        }
                    </Carousel>
                ) : ''
            }
            <div className="corporate-package-footer">
                <div className="notes">
                    <span className='text-danger'>**</span>Dengan melanjutkan pembayaran, sobat sudah menyetujui syarat dan ketentuan dan kebijakan privasi Landack.
                </div>
                {
                    props.prices.hasOwnProperty(packageIndex) ? (
                        <a href={ buildWaLink('berlangganan', props.prices[packageIndex].name) } target="_blank" className='btn-choose'>
                            Pesan Sekarang
                        </a>
                    ) : <LoadingPage />
                }
            </div>
        </>
    );
};

const CorporatePackageOptions = (props) => {
    const [ prices, setPrices ] = useState([]);

    useEffect( () => {
        let data = [];

        for(let key in props.serviceData.data) {
            data.push(props.serviceData.data[key]);
        }
        
        setPrices([...data]);
    }, [props.serviceData]);

    return (
        <div id="corporate-pricing" className="corporate-package-option-container">
            <CorporatePackageList prices={prices} selectedPackage={ props.selectedPackage } />
        </div>
    );
}



export default CorporatePackageOptions;