import React, { useEffect, useState, Fragment } from 'react';

import Init from '../../../Init/Init';
import './CorporatePackageList.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';
import { buildWaLink, numberFormatting, packageLink } from '../../../Util/Util';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = (state) => {
    return {
        package: state.package.list
    };
}

const CorporatePackageItem = (props) => {
    return (
        <Link to={`/paket-korporat/${props.code}`} className={"corporate-package-item v2"}>
            <div className="corporate-package-item-icon">
                <img src={props.icon} alt="Icon Layanan" />
            </div>
            <h3 className="corporate-package-item-title">
                { props.name } 
                {/* <FontAwesomeIcon icon={['fas', 'chevron-right']} /> */}
            </h3>
            <div className="corporate-package-item-desc" dangerouslySetInnerHTML={ {__html: props.description} }>

            </div>
        </Link>
    );
}

const CorporatePackageList = (props) => {
    const { FontAwesomeIcon, config } = Init;
    let { imageLogo } = config;
    const { prices } = props;

    const gaEventCategory = 'Paket Korporat';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);
    
    return (
        <div className="corporate-package-list">
            {
                prices.map( (v, k) => {
                    return (
                        <CorporatePackageItem 
                            key={k} 
                            name={ v.name } 
                            code={ v.code } 
                            icon={ v.icon } 
                            description={ v.packageDescription }
                        />
                    );
                } )
            }
        </div>
    );
};

const CorporatePackage = (props) => {
    const [ corporatePackage, setCorporatePackage ] = useState([]);

    useEffect( () => {
        let data = [];

        for(let index in props.package) {
            let item = props.package[index];

            data.push({
                code: item.package_code,
                name: item.package_name,
                icon: `${process.env.REACT_APP_API_PACKAGE_STORAGE}${item.app_icon_path}`,
                packageDescription: `${ item.package_description }`,
                isRecommended: item.is_recommended,
                isBestSeller: item.is_best_seller
            });
        }
        
        setCorporatePackage(data);
    }, [props.package]);

    return (
        <div id="corporate-pricing" className="corporate-package">
            <h2 className='corporate-package-title'>
                Berlangganan Paket Landack.
            </h2>
            <h5 className='corporate-package-subtitle'>
                Pilih paket lengkap untuk kebutuhanmu!
            </h5>
            {
                corporatePackage.length > 0 ? (
                    <CorporatePackageList prices={corporatePackage} />
                ) : (
                    <div className="legal-service-loading-section">
                        <LoadingPage />
                    </div>
                )
            }
        </div>
    );
}



export default connect(mapStateToProps)(CorporatePackage);