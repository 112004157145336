import { eventList } from '../../Data/Events';
import * as actionTypes from './event-types';

const loadEvent = () => {
    return dispatch => {
        eventList().then( (response) => {
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = response.data;

                dispatch(loadEventSuccess(data));
            }
        } );
    }
}

const loadEventSuccess = (data) => {
    return {
        type: actionTypes.EVENT_LIST,
        payload: {
            data
        }
    }
}

const packageActions = {
    loadEvent
}

export default packageActions;