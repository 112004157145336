import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './CorporatePackagePage.scss';

import CorporatePackageOptions from '../../Component/PagesComponent/CorporatePackageOptions/CorporatePackageOptions';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';

import { objectIsEmpty } from '../../Util/Util';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        data: state.package.data,
        list: state.package.list,
        isLoaded: state.package.isLoaded
    };
}

const CorporatePackagePage = (props) => {
    const [ service, setService ] = useState({});
    const param = useParams();
    const [ code, setCode ] = useState('');
    const navigate = useNavigate();

    useEffect( () => {
        props.checkPage('berlangganan-paket-landack.');

        if( param.hasOwnProperty('packageCode') ) {
            setCode( param.packageCode );
        } else {
            navigate('/');
        }

    }, []);

    useEffect( () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

        let { data, list } = props;
        
        setService({
            ...service,
            data,
            list
        });
    }, [ props.data, code ])


    return (
        <div className='corporate-package-page'>
            {
                !objectIsEmpty(service) && !objectIsEmpty(service.data) ? (
                    <CorporatePackageOptions serviceData={service} selectedPackage={code} />
                ) : (
                    <div className="corporate-package-loading-container">
                        <LoadingPage />
                    </div>
                )
            }
        </div>
    );
}

export default connect(mapStateToProps)(CorporatePackagePage);