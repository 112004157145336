import React from 'react';
import './Marquee.scss';

import Init from '../../../Init/Init';
const { FontAwesomeIcon } = Init;

function Marquee(props) {
     return (
        <div className="marquee">
            <FontAwesomeIcon className='marquee-icon' icon={['fas', 'info-circle']} /> <span className='marquee-text'>{ props.children }</span>
        </div>
    );
}

export default Marquee;