import Init from '../Init/Init';
import { responseFormat, getFormattedDatetime, buildWaLink } from '../Util/Util';
import api from './API';
import Cookie from 'universal-cookie';

const cookies = new Cookie();

const pathList = {
    list: `transaction/`,
    order: `transaction/order`,
    orderDetail: `transaction/:param`,
};

export const transactionList = async ( status = null ) => {
    try {
        const apiPath = pathList.list;
        const request = {};
        const token = cookies.get('authToken');

        request.status = status;

        const response = await api.getCall(apiPath, null, request, token !== null ? token : '');

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        } else {
            throw response.message;
        }

        return responseFormat(response.errorCode, 'Data Transaksi berhasil diambil!', data);
    } catch(e) {
        return responseFormat(-1, e.toString(), {});
    }
}

export const order = async (client_name, service_code, service_type, quantity, transaction_notes = '', additionalData = {}) => {
    try {
        const apiPath = pathList.order;
        const token = cookies.get('authToken');

        const response = await api.postCall(apiPath, null, {
            client_name,
            service_code,
            service_type,
            quantity,
            transaction_notes,
            is_web: 1,
            ...additionalData
        }, token !== null ? token : '');

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        } else {
            throw response.data;
        }

        return responseFormat(response.errorCode, 'Transaksi berhasil dibuat!', data);
    } catch(e) {
        return responseFormat(-1, 'Transaksi gagal dibuat!', e);
    }
};

export const transactionDetail = async( transactionCode, status = null ) => {
    try {
        const apiPath = pathList.orderDetail;
        const request = {
            is_web: 1
        };

        if( status !== null ) {
            request.status = status;
        }

        const response = await api.getCall(apiPath, transactionCode, request);

        let data;

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            data = response.data;
        } else {
            throw response.message;
        }

        return responseFormat(response.errorCode, 'Fetch transaction success!', data);
    } catch(e) {
        return responseFormat(-1, e.toString(), {});
    }
};