import React, {Component, Fragment, createRef} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import './Navbar.scss';
import {Link, Routes, BrowserRouter as Router, Route, NavLink, useParams, useSearchParams, useLocation, useNavigate} from "react-router-dom";

import Init from '../../../Init/Init';
import NavigationContext from '../../../Context/NavigationContext';

import { connect } from 'react-redux';
import navbarActions from '../../../Redux/Navbar/navbar-action';
import bannerActions from '../../../Redux/Banner/banner-action';
import legalServiceActions from '../../../Redux/LegalService/legal-service-action';
import packageActions from '../../../Redux/Package/package-action';
import businessEntityActions from '../../../Redux/BusinessEntity/business-entity-action';
import eventActions from '../../../Redux/Event/event-action';
import { authenticate } from '../../../Redux/Auth/auth-action';

import TopBar from '../TopBar/TopBar';
import BottomBar from '../BottomBar/BottomBar';

const { initNavbar, toggleShow, overrideBackFunc } = navbarActions;
const { loadBanner } = bannerActions;
const { loadLegalService } = legalServiceActions;
const { loadPackage } = packageActions;
const { loadBusinessEntity } = businessEntityActions;
const { loadEvent } = eventActions;

const withParameters = (Component) => {
    function ComponentWithRouter(props) {
        const params = useParams();
        const [queryParams] = useSearchParams();
        const location = useLocation();

        const navigate = useNavigate(); 

        return (
            <NavigationContext.Provider value={{
                navigate: (targetPath, options = {
                    replace: true,
                    state: {}
                }) => {
                    navigate(targetPath, options);
                },
                params,
                queryParams,
                location
            }}>
                <Component 
                    {...props} 
                />
            </NavigationContext.Provider>
        )
    }

    return ComponentWithRouter
}

const mapDispatchToProps = (dispatch) => {
    return {
        initNavbar: () => dispatch( initNavbar() ),
        toggle: (pageId) => dispatch( toggleShow(pageId) ),
        loadBanner: () => dispatch( loadBanner() ),
        loadLegalService: () => dispatch( loadLegalService() ),
        loadPackage: () => dispatch( loadPackage() ),
        loadBusinessEntity: () => dispatch( loadBusinessEntity() ),
        loadEvent: () => dispatch( loadEvent() ),
        overrideBackFunc: (goBack) => dispatch( overrideBackFunc(goBack) ),
        isAuthenticated: (token) => dispatch(authenticate(token))
    };
}
class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPath: window.location.pathname,
            navigationRef: createRef(),
            navObject: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        props.initNavbar();
        props.loadBanner();
        props.loadLegalService();
        props.loadPackage();
        props.loadBusinessEntity();
        props.loadEvent();
        props.isAuthenticated();

        let navObject = {};

        for( let index in props.navList ) {
            let item = props.navList[index];
            let id = item.name.toLowerCase().split(" ").join("-");

            navObject[id] = item;
        }
        
        return {
            ...state,
            navList: props.navList,
            navObject,
            show: props.show
        }
    }

    componentDidMount() {
        console.log('Initiate');
    }

    componentDidUpdate() {
        console.log('Navbar Updated');
    }

    getCurrentPath(path) {
        this.handleNavClick(path);
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };

        window.removeEventListener('resize', () => {});
    }

    convertToId( name ) {
        return name.toLowerCase().split(" ").join("-");
    }

    updatePage( name = '' ) {
        const pageId = this.convertToId(name);

        if( pageId !== '' ) {
            this.props.toggle(pageId);
        }
    }

    overrideBackFunc(backFunc) {
        this.props.overrideBackFunc(backFunc);
    }

    render() {
        return (
            <Fragment>
                <Router>
                    <TopBar />
                    {/* Render the first matched path */}
                    <Routes>
                        {
                            this.state.navList.map((route, i) => {
                                    const ComponentWithParameters = withParameters(route.component);

                                    return (
                                        <Route
                                            key={i}
                                            path={route.path}
                                            element={
                                                <ComponentWithParameters 
                                                    checkPage={ (name) => this.updatePage(name) }
                                                    goBack={ (backFunc = -1) => this.overrideBackFunc(backFunc) } 
                                                />}
                                        />
                                    )
                                } 
                            )
                        }
                    </Routes>
                    <BottomBar />
                </Router>
            </Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(NavigationBar);