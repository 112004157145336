import * as actionTypes from './legal-service-types';

const INITIAL_STATE = {
    data: {},
    list: [],
    isLoaded: false
}

const legalServiceReducer = (state = INITIAL_STATE, action) => {
    let { list, data } = state;
    
    switch(action.type) {
       case actionTypes.LEGAL_SERVICE_LIST:
            list = action.payload.data;

            for( let index in list ) {
                let item = list[index];

                // data[item.service_code] = item;
                data[item.service_code] = {
                    code: item.service_code,
                    name: item.service_name,
                    iconPath: item.app_icon_path,
                    description: item.description,
                    portion: item.portion,
                    unit: item.unit,
                    price: item.price, 
                    topicLabel: item.topic_label
                };
            }

            return {
                ...state,
                list,
                data,
                isLoaded: true
            };
       default: 
            return state;
    }
};

export default legalServiceReducer;