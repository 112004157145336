import React from 'react';
import './CustomerComplain.scss';

import Init from '../../../Init/Init';
import { buildWaLink } from '../../../Util/Util';

function CustomerComplain(props) {
    const { FontAwesomeIcon, config } = Init;
    const { whatsappIcon } = config;

    return (
        <>
            <div className="customer-complain-container">
                <div className="customer-complain">
                    <div className="customer-complain-header">
                        {/* <FontAwesomeIcon className='header-icon' icon={['fas', 'exclamation-circle']} />  */}
                        Layanan Pengaduan Konsumen
                    </div>
                    <div className="customer-complain-info">
                        PT Inspirasi Teknologi Bangsa
                        <br />
                        Email: info@landack.id
                    </div>
                    <div className="customer-complain-info">
                        Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga
                        <br />
                        Kementrian Perdagangan Republik Indonesia
                    </div>
                    <div className="customer-complain-info">
                        Whatsapp Ditjen PKTN: +62 853-1111-1010
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomerComplain;