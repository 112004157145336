import { businessEntityList } from '../../Data/BusinessEntity';
import * as actionTypes from './business-entity-types';

const loadBusinessEntity = () => {
    return dispatch => {
        businessEntityList().then( (response) => {
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = response.data;

                dispatch(loadBusinessEntitySuccess(data));
            }
        } );
    }
}

const loadBusinessEntitySuccess = (data) => {
    return {
        type: actionTypes.BUSINESS_ENTITY_OPTION,
        payload: {
            data
        }
    }
}

const businessEntityActions = {
    loadBusinessEntity
}

export default businessEntityActions;