import * as actionTypes from './auth-types';
import { convertQueryToObj } from '../../Util/Util';
import authAPI from '../../Data/Auth';
import Cookie from 'universal-cookie';

const cookies = new Cookie();
const { isAuthenticated, removeAuth, saveAccount, saveProfile, deleteAccount } = authAPI;

export const getToken = () => {
    const location = window.location;
    let authToken = '';

    let params = convertQueryToObj(location.search);
    if(params.hasOwnProperty('token') && params.token !== '') {
        authToken = params.token;
    } else {
        authToken = cookies.get('authToken');
    }

    return authToken;
};

export const authenticate = () => {
    return dispatch => {
        // Indicates auth process
        dispatch(authenticateProcess());
            
        // Get token from URL after redirection
        let authToken = getToken();

        // For current prod
        // dispatch(authenticateFailed());

        // Use token to validate
        if(authToken !== undefined && authToken !== null && authToken !== '') {
            ( async () => {
                const response = await isAuthenticated(authToken);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    if(response.data.hasOwnProperty('isLogin') && response.data.isLogin) {
                        cookies.set('authToken', authToken, {
                            path: '/'
                        });
    
                        dispatch(authenticateSuccess(response.data.customer));
                    } else if(response.data.hasOwnProperty('isLogin') && response.data.isLogin === false) {
                        cookies.remove('authToken');
                        dispatch(authenticateFailed());
                    } else {
                        dispatch(authenticateFailed());
                    }
                }
            } )();
            
        } else {
            dispatch(authenticateFailed());
        }
    }
};

const authenticateSuccess = (data = {}) => {
    return {
        type: actionTypes.AUTHENTICATE,
        payload: {
            data
        }
    }
};

const authenticateProcess = () => {
    return {
        type: actionTypes.AUTHENTICATING,
        payload: {
            data: {}
        }
    }
};

const authenticateFailed = () => {
    return {
        type: actionTypes.AUTHENTICATE_FAILED,
        payload: {}
    }
};

export const accountDelete = () => {
    return dispatch => {
        // Get token from URL after redirection
        let authToken = getToken();

        // Use token to revoke
        if(authToken !== undefined && authToken !== null && authToken !== '') {
            ( async () => {
                const response = await deleteAccount(authToken);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    dispatch(accountDeleteSuccess());
                }
            } )();
            
        } else {
            dispatch(accountDeleteFailed());
        }
    }
};

const accountDeleteSuccess = () => {
    cookies.remove('authToken');
    return {
        type: actionTypes.LOGOUT,
        payload: {}
    }
};

const accountDeleteFailed = () => {
    return {
        type: actionTypes.DELETE_ACC_FAILED,
        payload: {}
    }
}

export const logout = () => {
    return dispatch => {
        // Indicates auth process
        dispatch(logoutProcess());
            
        // Get token from URL after redirection
        const location = window.location;
        let authToken = '';

        let params = convertQueryToObj(location.search);
        if(params.hasOwnProperty('token') && params.token !== '') {
            authToken = params.token;
        } else {
            authToken = cookies.get('authToken');
        }

        // For current prod
        // dispatch(authenticateFailed());

        // Use token to validate
        if(authToken !== undefined && authToken !== null && authToken !== '') {
            ( async () => {
                const response = await removeAuth(authToken);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    cookies.remove('authToken');
                    dispatch(logoutSuccess());
                } else {
                    dispatch(logoutSuccess());
                }
            } )();
            
        } else {
            dispatch(logoutFailed());
        }
    }
};

const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT,
        payload: {}
    }
};

const logoutProcess = () => {
    return {
        type: actionTypes.AUTHENTICATING,
        payload: {}
    }
};

const logoutFailed = () => {
    return {
        type: actionTypes.AUTHENTICATE_FAILED,
        payload: {}
    }
};