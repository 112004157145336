import React, { useState, useEffect } from 'react';
import './TransactionPage.scss';
import Init from '../../Init/Init';
import { transactionList } from '../../Data/Transaction';
import { getFormattedDatetime, dateAddition, numberFormatting } from '../../Util/Util';
import { Link } from 'react-router-dom';

const transactionStatuses = Init.constants.transactionStatus;
const textConstant = Init.constants.text;
const serviceTypes = Init.constants.serviceType;
const { calendarIcon, clockIcon, calendarWithArrowIcon, qtyIcon } = Init.config;

/* 
    1. Tab to choose transaction status
    2. Transaction List
        - Transaction Component consists of:
          - Transaction Header (Status)
          - Transaction Body (Service Name, Trx Datetime, Duration, Ends on)
          - Transaction Footer (Total transaction value, Action Button)
          - Transaction Tail (Expiry time countdown)
*/
const TransactionPage = (props) => {
    const [ transactionStatus, setTransactionStatus ] = useState(null);
    const [ trxList, setTrxList ] = useState([]);
    const statusList = [];

    for( let key in transactionStatuses ) {
        statusList.push({
            value: transactionStatuses[key],
            label: textConstant[key]
        });
    }

    const chooseStatus = (status) => {
        setTransactionStatus(status);
    }

    useEffect( () => {
        ( async() => {
            const response = await transactionList(transactionStatus);
            let transaction = [];

            if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                for( let index in response.data ) {
                    let item = response.data[index];

                    transaction.push({
                        id: item.id, // Unique ID
                        // Header
                        status: item.status_message,
                        // Body
                        service_type: item.service_type,
                        icon_path: item.icon_path,
                        app_icon_path: item.app_icon_path,
                        service_code: item.service_code,
                        service_name: item.service_name,
                        created_at: getFormattedDatetime(item.created_at),
                        chat_duration: '2x ' + item.chat_duration,
                        ends_on: dateAddition(item.created_at, item.chat_duration * 60),
                        quantity: item.quantity * item.service_portion,
                        unit: item.unit,
                        // Footer
                        total_price: item.total_price,
                        transaction_code: item.transaction_code
                        // If Exists
                        // Expired Time for expiry countdown
                    });
                }
            }

            setTrxList(transaction);
        } )();
    }, [ transactionStatus ]);

    return (
        <div className="transaction-page">
            <div className="transaction-tab-container">
                <div className="transaction-tabs">
                    <div className={`transaction-tab-item ${ transactionStatus === null ? 'active' : '' }`} onClick={ () => chooseStatus(null) }>
                        Semua Transaksi
                    </div>
                    {
                        statusList.map( (item, index) => {
                            return (
                                <div key={ index } className={`transaction-tab-item ${ transactionStatus === item.value ? 'active' : '' }`} onClick={ () => chooseStatus(item.value) }>
                                    { item.label }
                                </div>
                            )
                        } )
                    }
                </div>
            </div>
            <div className="transaction-list">
                {
                    trxList.map( (item, index) => {
                        let assetFolder = '';

                        switch( item.service_type ) {
                            case serviceTypes.legalService:
                                assetFolder = process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE;
                                break;
                            case serviceTypes.package:
                                assetFolder = process.env.REACT_APP_API_PACKAGE_STORAGE;
                                break;
                            case serviceTypes.businessEntity:
                                assetFolder = process.env.REACT_APP_API_BUSINESS_ENTITY_STORAGE;
                                break;
                            case serviceTypes.event:
                                assetFolder = process.env.REACT_APP_API_EVENT_STORAGE;
                                break;
                            default:
                                break;
                        }

                        console.log(item);

                        return (
                            <div key={ index } className="transaction-item">
                                <div className="transaction-item-header">
                                    <div className="header-label">
                                        Status
                                    </div>
                                    <div className="header-value">
                                        { item.status }
                                    </div>
                                </div>
                                <div className="transaction-item-body">
                                    <div className="service-icon">
                                        <img src={ `${ assetFolder }${ item.app_icon_path }` } alt={ item.service_name } />
                                    </div>
                                    <div className="service-detail">
                                        <span className="service-name">
                                            { item.service_name }
                                        </span>
                                        <span className="datetime service-info">
                                            <img className="transaction-icon" src={ calendarIcon } /> { item.created_at }
                                        </span>
                                        <span className="duration service-info">
                                            <img className="transaction-icon" src={ qtyIcon } /> { item.quantity } { item.unit }
                                        </span>
                                        <span className="ends-on service-info">
                                            <img className="transaction-icon" src={ calendarWithArrowIcon } /> { item.ends_on }
                                        </span>
                                    </div>
                                </div>
                                <div className="transaction-item-footer">
                                    <div className="total-transaction">
                                        Total: { numberFormatting(item.total_price) }
                                    </div>
                                    <div className="action-button-container">
                                        <Link className='btn btn-detail' to={`/transaksi/${ item.transaction_code }`}>
                                            Detil Transaksi
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    } )
                }
            </div>
        </div>
    );
}

export default TransactionPage;