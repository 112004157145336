import * as actionTypes from './navbar-types';

const initNavbar = () => {
    return {
        type: actionTypes.INIT_NAVBAR,
        payload: {}
    }
}

const toggleShow = (pageId) => {
    return {
        type: actionTypes.TOGGLE_SHOW,
        payload: {
            pageId
        }
    }
}

const overrideBackFunc = (goBack) => {
    return {
        type: actionTypes.OVERRIDE_BACK_FUNCTION,
        payload: {
            goBack
        }
    }
}

const navbarActions = {
    initNavbar,
    toggleShow,
    overrideBackFunc
}

export default navbarActions;