import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './SuccessPage.scss';

import ServiceHeader from '../../Component/Utilities/ServiceHeader/ServiceHeader';
import ServiceFooter from '../../Component/Utilities/ServiceFooter/ServiceFooter';
import CheckoutDetail from '../../Component/PagesComponent/CheckoutDetail/CheckoutDetail';
import Marquee from '../../Component/Utilities/Marquee/Marquee';

import { transactionDetail } from '../../Data/Transaction';
import { getFormattedDatetime, numberFormatting, objectIsEmpty } from '../../Util/Util';

import Init from '../../Init/Init';
const { constants, config } = Init;
const { whatsappIcon, puzzleIcon, justiceIcon, userIcon } = config;

const SuccessPage = (props) => {
    const [ transaction, setTransaction ] = useState({});
    const [ orderDetail, setOrderDetail ] = useState([]);
    const [ priceDetail, setPriceDetail ] = useState([]);

    const [ buttonLabel, setButtonLabel ] = useState('');
    const [ buttonDesc, setButtonDesc ] = useState('');
    
    const [ inputs, setInputs ] = useState({});

    const params = useParams();
    const navigate = useNavigate();

    useEffect( () => {
        props.checkPage('pembayaran-berhasil');

        props.goBack( () => {
            navigate('/');
        } );

        ( async () => {
            if( params.hasOwnProperty('transactionCode') && params.transactionCode !== null ) {
                const trx = await transactionDetail(params.transactionCode);
                let data = {};

                if( trx.hasOwnProperty('errorCode') && trx.errorCode === 0 ) {

                    if( [
                            constants.transactionStatus.waitingForPayment,
                            constants.transactionStatus.onQueue,
                            constants.transactionStatus.onGoing
                        ].includes(trx.data.transaction_status) 
                    ) {
                        let assetFolder = '';
    
                        switch( trx.data.service_type ) {
                            case constants.serviceType.legalService:
                                assetFolder = `${ process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE }`;
                                break;
                            case constants.serviceType.package:
                                assetFolder = `${ process.env.REACT_APP_API_PACKAGE_STORAGE }`;
                                break;
                            case constants.serviceType.businessEntity:
                                assetFolder = `${ process.env.REACT_APP_API_BUSINESS_ENTITY_STORAGE }`;
                                break;
                            case constants.serviceType.event:
                                assetFolder = `${ process.env.REACT_APP_API_EVENT_STORAGE }`;
                                break;
                            default:
                                break;
                        }

                        let transactionNotes = trx.data.transaction_notes;
    
                        data = {
                            serviceName: trx.data.service_data.service_name,
                            iconPath: `${assetFolder}${trx.data.service_data.app_icon_path}`,
                            description: trx.data.service_data.hasOwnProperty('description') ? trx.data.service_data.description : trx.data.service_data.service_name,
                            clientId: trx.data.client_id,
                            clientName: trx.data.client_name,
                            whatsappNo: trx.data.whatsapp_no,
                            transactionNotes: transactionNotes,
                            serviceType: trx.data.service_type,
                            lawFirmName: trx.data.law_firm_name,
                            transactionCode: trx.data.transaction_code,
                            chatDuration: trx.data.chat_duration,
                            chatFrequency: trx.data.chat_frequency,
                            quantity: trx.data.quantity * (trx.data.service_data.hasOwnProperty('portion') ? trx.data.service_data.portion : 1),
                            unit: trx.data.unit,
                            transactionDate: trx.data.created_at,
                            totalPrice: parseInt(trx.data.total_price),
                            whatsappUrl: trx.data.whatsapp_url,
                            transactionStatus: trx.data.transaction_status,
                            statusMessage: trx.data.status_message,
                            paymentLink: trx.data.payment_link,
                            topicLabel: trx.data.service_data.hasOwnProperty('topic_label') && trx.data.service_data.topic_label ? trx.data.service_data.topic_label : 'Inti Permasalahan'
                        };
    
                        setTransaction({
                            ...data
                        });
                    } else {
                        navigate('/', {
                            replace: true
                        });
                    }
                }
            }
        } )();
    }, [params.transactionCode]);

    useEffect( () => {
        if( !objectIsEmpty( transaction ) ) {
            let dataInput = {
                whatsappNo: {
                    label: 'No. Whatsapp',
                    icon: whatsappIcon,
                    value: transaction.whatsappNo,
                },
                topic: {
                    label: transaction.topicLabel,
                    icon: puzzleIcon,
                    value: transaction.transactionNotes
                }
            };

            if( transaction.hasOwnProperty('clientId') && !isNaN(parseInt(transaction.clientId)) ) {
                dataInput = {
                    clientName: {
                        label: 'Nama Klien',
                        icon: userIcon,
                        value: transaction.clientName
                    },
                    ...dataInput
                }
            }

            if( transaction.hasOwnProperty('serviceType') && transaction.serviceType !== constants.serviceType.event ) {
                dataInput = {
                    ...dataInput,
                    lawFirmName: {
                        label: 'Kantor Hukum',
                        icon: justiceIcon,
                        value: transaction.lawFirmName
                    }
                }
            }

            setInputs(dataInput);

            const orderInfo = [
                {label: 'No. Pesanan', value: transaction.transactionCode},
                {label: 'Status Pesanan', value: transaction.statusMessage},
                {label: 'Jenis Layanan', value: transaction.serviceName}
            ];

            if( transaction.unit !== 'menit' ) {
                orderInfo.push({label: 'Total', value: `${transaction.quantity} ${transaction.unit}`});
            }
    
            if( transaction.chatDuration > 0 ) {
                orderInfo.push({label: 'Durasi Chat', value: `${transaction.chatDuration} Menit`});
            }
    
            orderInfo.push({label: 'Waktu Pemesanan', value: `${ getFormattedDatetime(transaction.transactionDate, 'd M Y H:i', true) }`});
    
            setOrderDetail([
                ...orderInfo
            ]);
    
            const priceInfo = [
                {label: 'Total', value: transaction.totalPrice},
            ];
    
            setPriceDetail([
                ...priceInfo
            ]);

            updateButton();
        }
    }, [transaction]);

    const updateButton = () => {
        if(transaction.transactionStatus === constants.transactionStatus.waitingForPayment) {
            setButtonLabel(`Lanjutkan Pembayaran`);
            setButtonDesc(`<span class='text-danger'>**</span>Dengan melanjutkan pembayaran, sobat sudah menyetujui syarat dan ketentuan dan kebijakan privasi Landack.`);
        } else if( transaction.serviceType === constants.serviceType.event ) {
            setButtonLabel(`Kembali ke Halaman Utama`);
            setButtonDesc(``);
        } else if( [constants.transactionStatus.onQueue, constants.transactionStatus.onGoing].includes(transaction.transactionStatus) ) {
            setButtonLabel(`Lanjut ke Layanan Sekarang`);
            setButtonDesc(`<span class='text-danger'>**</span>Dengan menekan tombol dibawah ini, sobat akan di redirect ke WhatsApp untuk melanjutkan layanan.`);
        } else {
            setButtonLabel(``);
            setButtonDesc(``);
        }
    }

    const proceedToService = () => {
        console.log('Proceed to WhatsApp for service!');
        
        if( transaction.transactionStatus === constants.transactionStatus.waitingForPayment ) {
            window.open( transaction.paymentLink, "_blank" );
        } else if( transaction.serviceType === constants.serviceType.event ) {
            navigate("/");
        } else if([constants.transactionStatus.onQueue, constants.transactionStatus.onGoing].includes(transaction.transactionStatus)) {
            window.open( transaction.whatsappUrl, "_blank" );
        }
    }

    return (
        <div className='success-page'>
            {/* <Marquee text={ `Mohon untuk menyimpan no pesanan anda supaya bisa tetap memakai layanan kami setelah membayar` } /> */}
            <Marquee>
                Mohon untuk menyimpan no pesanan anda supaya bisa tetap memakai layanan kami setelah membayar
            </Marquee>
            <div className="success-page-container">
                <ServiceHeader name={ transaction.serviceName } iconPath={ `${ transaction.iconPath }` } description={ transaction.description } />
                <CheckoutDetail order={ orderDetail } price={ priceDetail } inputs={ inputs } displayOnly={true} />
            </div>
            <ServiceFooter
                label={ buttonLabel }
                description={buttonDesc}
                onClick={ () => proceedToService() }
                disabled={ transaction.status > constants.transactionStatus.onGoing }
            />
        </div>
    );
}

export default SuccessPage;