import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './EventPage.scss';

import EventList from '../../Component/PagesComponent/EventList/EventList';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';

import { objectIsEmpty } from '../../Util/Util';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        data: state.event.list,
        isLoaded: state.event.isLoaded
    };
}

const EventPage = (props) => {
    const [ events, setEvents ] = useState(null);
    const navigate = useNavigate();

    useEffect( () => {
        props.checkPage('event');
    }, []);

    useEffect( () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

        setEvents(props.data);
    }, [ props.data, props.isLoaded ]);

    return (
        events !== null ? (
            <div className='event-page'>
                <EventList data={ events } />
            </div>
        ) : (
            <div className="event-loading-container">
                <LoadingPage />
            </div>
        )
    );
}

export default connect(mapStateToProps)(EventPage);