import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import Init from '../../../Init/Init';
import './Events.scss';

import { connect } from 'react-redux';

import EventItem from '../EventItem/EventItem';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

const mapStateToProps = (state) => {
    return {
        events: state.event.list
    }
}

const Events = (props) => {
    const [ events, setEvents ] = useState(null);

    useEffect( () => {
        if( props.events !== null ) {
            setEvents( props.events );
        } else {
            setEvents( [] );
        }
    }, [ props.events ]);

    /**
     * Carousels
     */
    let carouselConfig = Init.config.carousel;
    /**
     * Make sure to customize config before storing it to state 
     * if necessary.
     * 
     * E.g: 
     * let carouselConfig = Init.config.carousel
     * carouselConfig.swipeable = false?
     */

    carouselConfig.containerClass = "event-carousel-container";
    carouselConfig.itemClass = "event-item-holder";
    carouselConfig.showDots = false;
    carouselConfig.infinite = false;
    carouselConfig.partialVisible = false;
    carouselConfig.autoPlay = false;
    carouselConfig.dotListClass = 'event-item-dot-list';
    carouselConfig.responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1550 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
            paritialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: { max: 1550, min: 1200 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
            paritialVisibilityGutter: 30
        },
        mobileTablet: {
            breakpoint: { max: 1200, min: 800 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
            paritialVisibilityGutter: 30
        },
        mobile: {
            breakpoint: { max: 800, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
            paritialVisibilityGutter: 30
        }
    }

    carouselConfig.removeArrowOnDeviceType = [
        // 'desktop',
        // 'tablet',
        'mobileTablet',
        'mobile'
    ];

    return (
        <div className='event'>
            {/* 
                2 parts:
                1. Title
                2. Button to redirect to another page
            */}
            <div className="event-header">
                <h2 className='event-title'>
                    Acara Landack.
                </h2>
                <h5 className='event-subtitle'>
                    Pilihan acara yang bermanfaat untuk Sobat!
                </h5>
            </div>
            <Carousel
                swipeable={carouselConfig.swipeable}
                draggable={carouselConfig.draggable}
                showDots={carouselConfig.showDots}
                responsive={carouselConfig.responsive}
                ssr={carouselConfig.ssr} // means to render carousel on server-side.
                infinite={carouselConfig.infinite}
                autoPlay={carouselConfig.autoPlay}
                autoPlaySpeed={carouselConfig.autoPlaySpeed}
                keyBoardControl={carouselConfig.keyBoardControl}
                transitionDuration={carouselConfig.transitionDuration}
                removeArrowOnDeviceType={carouselConfig.removeArrowOnDeviceType}
                deviceType={carouselConfig.deviceType}
                containerClass={carouselConfig.containerClass}
                dotListClass={carouselConfig.dotListClass}
                itemClass={carouselConfig.itemClass}
                // partialVisbile={carouselConfig.partialVisible}
            >
                {
                    events !== null ?
                    events.map( (item, index) => {
                        return (
                            <Link to={`/event/${item.id}`} key={index} className="event-choice">
                                <EventItem
                                    eventBanner={ item.event_banner }
                                    eventName={ item.event_name }
                                    eventDesc={ item.event_desc }
                                />
                            </Link>
                        )
                    } ) : (
                        <div className="event-loading-container">
                            <LoadingPage />
                        </div>
                    )
                }
            </Carousel>
        
            <div className="event-footer">
                <Link to={"/event"} className='event-see-more'>
                    Lihat lebih banyak...
                </Link>
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(Events);