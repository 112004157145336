import { packageList } from '../../Data/Package';
import * as actionTypes from './package-types';

const loadPackage = () => {
    return dispatch => {
        packageList().then( (response) => {
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = response.data;

                dispatch(loadPackageSuccess(data));
            }
        } );
    }
}

const loadPackageSuccess = (data) => {
    return {
        type: actionTypes.PACKAGE_LIST,
        payload: {
            data
        }
    }
}

const packageActions = {
    loadPackage
}

export default packageActions;