import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Init from '../../../Init/Init';
import { buildWaLink, legalServiceLink, numberFormatting } from '../../../Util/Util';

import './LegalService.scss';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        legalServices: state.legalService.list
    };
}


const LegalServiceList = (props) => {
    const { prices } = props;
    
    const gaEventCategory = 'Layanan Hukum';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    return (
        <div className='legal-service-list'>
            {
                prices.map( (v, k) => {
                    return (
                        <Link
                            key={k} 
                            className="legal-service-item"
                            to={ `/layanan-hukum/${v.code}` } 
                            // onClick={ () => gaEventTracker('Click Order Now Button', v.actionLabel)}
                            // onMouseEnter={ () => gaEventTracker('Hover Order Now Button', v.actionLabel)}
                        >
                            <div className="legal-service-icon-container">
                                <img src={v.icon} alt="Icon Layanan" className='legal-service-item-icon' />
                            </div>
                            <h3 className="legal-service-item-title">
                                { v.name }
                            </h3>
                        </Link>
                    );
                } )
            }
        </div>
    );
}

const LegalService = (props) => {
    const [ legalService, setLegalService ] = useState([]);

    useEffect( () => {
        let data = [];

        for(let index in props.legalServices) {
            let item = props.legalServices[index];

            data.push({
                code: item.service_code,
                name: item.service_name,
                icon: `${process.env.REACT_APP_API_LEGAL_SERVICE_STORAGE}${item.app_icon_path}`,
                description: `${item.description}`,
                price: `${numberFormatting(item.price)} / ${ (item.portion > 1 ? item.portion + ' ' : '') + item.unit }`,
                actionLabel: item.service_name
            });
        }
        
        setLegalService(data);

    }, [props.legalServices]);

    return (
        <div id="individual-pricing" className="legal-service">
            <h2 className='legal-service-title'>
                Layanan Hukum
            </h2>
            {
                legalService.length > 0 ? (
                    <LegalServiceList prices={legalService} />
                ) : (
                    <div className="legal-service-loading-section">
                        <LoadingPage />
                    </div>
                )
            }
        </div>
    );
}



export default connect(mapStateToProps)(LegalService);