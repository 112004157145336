import Homepage from '../Container/Homepage/Homepage';

import ErrorPage from '../Container/ErrorPage/ErrorPage';
import ComingSoon from '../Component/PagesComponent/ComingSoon/ComingSoon';

import LegalServicePage from '../Container/LegalServicePage/LegalServicePage';
import BusinessEntityPage from '../Container/BusinessEntityPage/BusinessEntityPage';
import CorporatePackagePage from '../Container/CorporatePackagePage/CorporatePackagePage';

import CheckoutPage from '../Container/CheckoutPage/CheckoutPage';
import SuccessPage from '../Container/SuccessPage/SuccessPage';

import TransactionPage from '../Container/TransactionPage/TransactionPage';
import TransactionDetailPage from '../Container/TransactionDetailPage/TransactionDetailPage';

import ProfilePage from '../Container/ProfilePage/ProfilePage';
import ProfileDetailPage from '../Container/ProfileDetailPage/ProfileDetailPage';
import AccountDetailPage from '../Container/AccountDetailPage/AccountDetailPage';
import EventPage from '../Container/EventPage/EventPage';
import EventDetailPage from '../Container/EventDetailPage/EventDetailPage';
import EventRegistrationPage from '../Container/EventRegistrationPage/EventRegistrationPage';

/**
 * 1. Banner / tagline
 * 2. Landack. itu apa sih (visi misi)
 * 3. Bisnis kita apa, produk kita apa
 * 4. Keunggulan kita apa
 * 5. Pricing
 * 6. Accesibility
 * 7. FAQ
 * 8. Artikel
 * 9. How to contact us
 * 10. Pengenalan founder (optional)
 */
const Routes = () => {
    return [
        {
            path: "/",
            name: "Beranda",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: Homepage,
            icon: 'home.png',
            activeIcon: 'home-filled.png'
        },
        {
            path: "/layanan-hukum/:serviceCode",
            name: "Layanan Hukum",
            visibility: false,
            showNav: false,
            showFooter: true,
            component: LegalServicePage
        },
        {
            path: "/transaksi",
            name: "Log Aktivitas",
            visibility: true,
            showNav: true,
            showFooter: true,
            component: TransactionPage,
            icon: 'activity.png',
            activeIcon: 'activity-filled.png'
        },
        {
            path: "/transaksi/:transactionCode",
            name: "Detil Transaksi",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: TransactionDetailPage
        },
        {
            path: "/pendirian-badan-usaha/:businessEntityCode",
            name: "Pendirian Badan Usaha",
            visibility: false,
            showNav: false,
            showFooter: true,
            component: BusinessEntityPage
        },
        {
            path: "/paket-korporat/:packageCode?",
            name: "Berlangganan Paket Landack.",
            visibility: false,
            showNav: false,
            showFooter: true,
            component: CorporatePackagePage
        },
        {
            path: "/pembayaran-berhasil/:transactionCode",
            name: "Pembayaran Berhasil",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: SuccessPage

        },
        {
            path: "/menu",
            name: "Menu",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: ProfilePage
        },
        {
            path: "/menu/account",
            name: "Pengaturan Akun",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: AccountDetailPage
        },
        {
            path: "/menu/profile",
            name: "Data Diri",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: ProfileDetailPage
        },
        {
            path: "/coming-soon",
            name: "Coming Soon",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: ComingSoon
        },
        {
            path: "/order/:serviceType/:serviceCode",
            name: "Order",
            visibility: false,
            showNav: true,
            showFooter: false,
            component: CheckoutPage
        },
        {
            path: "/event",
            name: "Event",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: EventPage
        },
        {
            path: "/event/:eventId",
            name: "Detail Event",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: EventDetailPage
        },
        {
            path: "/event-registration",
            name: "Pendaftaran Event",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: EventRegistrationPage
        },
        {
            path: "*",
            name: "Default",
            visibility: false,
            showNav: false,
            showFooter: false,
            component: ErrorPage
        }
    ];
}

export default Routes;