import * as actionTypes from './event-types';

const INITIAL_STATE = {
    data: {},
    list: [],
    isLoaded: false
}

const eventReducer = (state = INITIAL_STATE, action) => {
    let { data, list } = state;
    
    switch(action.type) {
       case actionTypes.EVENT_LIST:
            list = action.payload.data;

            return {
                ...state,
                list,
                data,
                isLoaded: true
            };
       default: 
            return state;
    }
};

export default eventReducer;