import React from 'react';
import './ServiceHeader.scss';

import { Accordion } from 'react-bootstrap';

function ServiceHeader(props) {
    const checkCollapse = (event) => {
        
    }

    return (
        <Accordion onClick={ (event) => checkCollapse(event) } defaultActiveKey="0">
            <Accordion.Item eventKey='0' className="service-header">
                <Accordion.Header>
                    <div className="service-icon">
                        <img src={ `${props.iconPath}` } alt="" />    
                    </div>
                    <h2 className="service-name">
                        { props.name }
                    </h2>
                </Accordion.Header>
                <Accordion.Body>
                    { props.description }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

ServiceHeader.defaultProps = {
    name: '',
    iconPath: '',
    description: '',
    collapse: true
}

export default ServiceHeader;