import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfilePage.scss';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../Redux/Auth/auth-action';

import Init from '../../Init/Init';
import { buildWaLink, getGoogleOauthURL, objectIsEmpty } from '../../Util/Util';
import CustomerComplain from '../../Component/PagesComponent/CustomerComplain/CustomerComplain';

import AppleLogin from 'react-apple-login';

const { FontAwesomeIcon, config } = Init;
const { userIcon, settingIcon, csIcon, faqIcon, infoIcon, privacyIcon, termsIcon, logoutIcon, googleIcon, appleIcon, apple } = config;

const mapStateToProps = (state) => {
    return {
        profile: state.auth.data,
        isLogin: state.auth.isLogin,
        isAuthenticating: state.auth.isAuthenticating
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // isAuthenticated: (token) => dispatch(authenticate(token))
        logout: () => dispatch(logout())
    }
}

const ProfilePage = (props) => {
    const webUrl = process.env.REACT_APP_COMPANY_PROFILE_WEB_URL;
    const [ profile, setProfile ] = useState({});
    const [ isLogin, setIsLogin ] = useState(false);
    const [ isAuthenticating, setIsAuthenticating ] = useState(false);
    const navigate = useNavigate();

    useEffect( () => {
        window.scroll({
            top: 0,
            behaviour: 'smooth'
        });

        props.checkPage('menu');
    }, []);

    useEffect( () => {
        setProfile(props.profile);
    }, [ isLogin, props.profile ]);

    useEffect( () => {
        setIsLogin( props.isLogin );
    }, [ props.isAuthenticating, props.isLogin ]);

    const logout = () => {
        props.logout();
        navigate('/');
    }

    return (
        /* 
            1. Profile Header
                - Profile Pic
                - Welcome, [username]
            2. Sisa Paket [Kalo ada]
            3. Profile & Account Menu
            4. Misc Menu
            5. Logout
        */
        <div className='profile-page'>
            {
                isLogin ? (
                    <>
                        <div className="profile-header">
                            <div className="profile-picture">
                                <img src={ profile.profile_photo } alt="" />
                            </div>
                            <div className="profile-name">
                                Selamat Datang, { profile.customer_username }!
                            </div>
                        </div>
                        {
                            !objectIsEmpty( profile.active_package ) ? (
                                <div className="profile-package">
                                    {/* 
                                        1. Package Icon
                                        2. Package Name
                                        3. Package Completion Gauge
                                        4. Detail Link
                                    */}
                                </div>
                            ) : ''
                        }
                        
                        <div className="profile-menu profile-account-management">
                            {/* 
                                Dont Forget the Title
                                1. Profile Management
                                2. Account Management
                            */}
                            <h6 className="menu-title">
                                Akun Saya
                            </h6>
                            <div className="menu-list">
                                <Link to={ "/menu/profile" } className="menu-item">
                                    <span className="icon-wrapper">
                                        <img src={ userIcon } alt="" />
                                    </span>
                                    <span className="menu-text">
                                        Data Diri
                                    </span>
                                </Link>
                                <Link to={ "/menu/account" } className="menu-item">
                                    <span className="icon-wrapper">
                                        <img src={ settingIcon } alt="" />
                                    </span>
                                    <span className="menu-text">
                                        Pengaturan Akun
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="profile-header">
                        <div className="profile-btn-login-container">
                            <a className={'profile-btn-login google'} href={ getGoogleOauthURL() }>
                                <img src={ googleIcon } alt="Google Icon" className="profile-btn-icon" />
                                Masuk dengan akun Google
                            </a>
                            <AppleLogin 
                                clientId={ apple.clientId } 
                                responseMode={ apple.responseMode }
                                redirectURI={ apple.redirectUrl }
                                scope={ apple.scope }
                                render={ ( props ) => {
                                return (
                                    <button className={'profile-btn-login apple'} onClick={ props.onClick }>
                                        <img src={ appleIcon } alt="Apple Icon" className="profile-btn-icon" />
                                        Masuk dengan akun Apple
                                    </button>
                                )
                            } } />
                        </div>
                    </div>
                    // ''
                )
            }
            <div className="profile-menu profile-misc">
                {/* 
                    Dont Forget the Title
                    1. Customer Service -> To Landack. WA
                    2. FAQ -> FAQ Landack.
                    3. Tentang Landack. -> About Us(?) [Drop aja klo gada]
                    4. Kebijakan Privasi -> landack.id/privacy-policy
                    5. Syarat dan Ketentuan -> landack.id/terms-and-condition
                */}
                <h6 className="menu-title">
                    Aplikasi Landack.
                </h6>
                <div className="menu-list">
                    <a href={ buildWaLink("minta", "bantuan layanan pelanggan") } className="menu-item" target="_blank">
                        <span className="icon-wrapper">
                            <img src={ csIcon } alt="" />
                        </span>
                        <span className="menu-text">
                            Bantuan Layanan Pelanggan
                        </span>
                    </a>
                    <a href={ `${webUrl}faq` } className="menu-item" target="_blank">
                        <span className="icon-wrapper">
                            <img src={ faqIcon } alt="" />
                        </span>
                        <span className="menu-text">
                            FAQ
                        </span>
                    </a>
                    <a href={ `${webUrl}` } className="menu-item" target="_blank">
                        <span className="icon-wrapper">
                            <img src={ infoIcon } alt="" />
                        </span>
                        <span className="menu-text">
                            Tentang Landack.
                        </span>
                    </a>
                    <a href={ `${webUrl}privacy-policy` } className="menu-item" target="_blank">
                        <span className="icon-wrapper">
                            <img src={ privacyIcon } alt="" />
                        </span>
                        <span className="menu-text">
                            Kebijakan Privasi
                        </span>
                    </a>
                    <a href={ `${webUrl}terms-and-condition` } className="menu-item" target="_blank">
                        <span className="icon-wrapper">
                            <img src={ termsIcon } alt="" />
                        </span>
                        <span className="menu-text">
                            Syarat dan Ketentuan
                        </span>
                    </a>
                </div>
            </div>

            {
                isLogin ? (
                    <div className="profile-menu profile-logout">
                        {/*                 
                            1. Logout Button -> Refresh to homepage after logging out success
                        */}
                        <div className="menu-list">
                            <button onClick={ () => logout() } className="menu-item" target="_blank">
                                <span className="icon-wrapper">
                                    <img src={ logoutIcon } alt="" />
                                </span>
                                <span className="menu-text">
                                    Log out
                                </span>
                            </button>
                        </div>
                    </div>
                ) : ''
            }

            <CustomerComplain />
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);