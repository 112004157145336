import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';

import Init from '../../../Init/Init';
import Routes from '../../../Routing/Routing';

import { connect } from 'react-redux';

import './BottomBar.scss';

const mapStateToProps = (state) => {
    return {
        title: state.navbar.pageTitle,
        showNav: state.navbar.show,
        isLogin: state.auth.isLogin
    }
}

const { FontAwesomeIcon, config } = Init;
const { iconFolder, ctaButton } = config;

function BottomBar(props) {
    const [ showNav, setShowNav ] = useState(true);
    const [ routes, setRoutes ] = useState([]);
    const convertToId = ( name ) => {
        return name.toLowerCase().split(" ").join("-");
    };

    useEffect( () => {
        let routeData = Routes();

        if( props.isLogin ) {
            for( let index in routeData ) {
                let item = routeData[index];
    
                if( item.hasOwnProperty('visibility') && item.visibility ) {
                    if( item.path === window.location.pathname ) {
                        item.isActive = true;
                    }
                }
    
                routeData[index] = item;
            }
        } else {
            routeData = [];
        }

        setRoutes(routeData);
    }, [ props.isLogin ]);
    
    useEffect( () => {
        setShowNav(props.showNav);
    }, [props.showNav]);

    const toggleIcon = (navName) => {
        const routeCopy = [...routes];
        const linkId = convertToId(navName);

        for( let index in routeCopy ) {
            var routeItem = routeCopy[index];
            var navLink = document.getElementById(convertToId(routeItem.name));

            routeItem.isActive = false;

            if(routeItem && routeItem.hasOwnProperty('visibility') && routeItem.visibility) {
                if(navLink.id === linkId) {
                    routeItem.isActive = true;
                }
            }

            routeCopy[index] = routeItem;
        }

        setRoutes([...routeCopy]);
    }

    return (
        <Navbar variant='dark' id="navigation-bar" expand="xl" className={`custom-navbar-bg ${showNav ? '' : 'd-none'}`}>
            <Nav className="bottom-navbar">
                <NavLink
                    to="/layanan-hukum/KH"
                    id="konsultasi-sekarang"
                    className="nav-link ml-2"
                >
                    {/* <span className="icon-wrapper">
                        <FontAwesomeIcon icon={ ["fas", 'comment'] } />
                    </span> Konsultasi Sekarang */}
                    <span className="icon-wrapper">
                        <img src={ ctaButton } />
                    </span> Konsultasi Sekarang

                </NavLink>
                {
                    routes.map( (item, index) => {
                        let navLinks = "";

                        if(item.hasOwnProperty('visibility') && item.visibility) {
                            navLinks = (
                                <NavLink key={index} 
                                    to={item.path} 
                                    // activeClassName="active" 
                                    id={ convertToId(item.name) }
                                    className="nav-link ml-2 bottom-nav-link" 
                                    onClick={ () => toggleIcon(item.name) }
                                >
                                    <span className="icon-wrapper">
                                        {
                                            item.hasOwnProperty('icon') && item.hasOwnProperty('activeIcon') ? (
                                                item.hasOwnProperty('isActive') && item.isActive ? (
                                                    <img src={ `${iconFolder}${item.activeIcon}` } alt="" />
                                                ) : (
                                                    <img src={ `${iconFolder}${item.icon}` } alt="" />
                                                )
                                            ) : ('')
                                        }
                                    </span>
                                    {item.name} 
                                </NavLink>
                            );
                        }

                        return navLinks;
                    } )
                }
            </Nav>
        </Navbar>
    );
}

export default connect(mapStateToProps)(BottomBar);