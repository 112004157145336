import { legalServiceList } from '../../Data/LegalService';
import * as actionTypes from './legal-service-types';

const loadLegalService = () => {
    return dispatch => {
        legalServiceList().then( (response) => {
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = response.data;

                dispatch(loadLegalServiceSuccess(data));
            }
        } );
    }
}

const loadLegalServiceSuccess = (data) => {
    return {
        type: actionTypes.LEGAL_SERVICE_LIST,
        payload: {
            data
        }
    }
}

const legalServiceActions = {
    loadLegalService
}

export default legalServiceActions;