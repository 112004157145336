import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './BusinessEntityPage.scss';

import BusinessEntityList from '../../Component/PagesComponent/BusinessEntityList/BusinessEntityList';
import BusinessEntityDetail from '../../Component/PagesComponent/BusinessEntityDetail/BusinessEntityDetail';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';

import { objectIsEmpty } from '../../Util/Util';

import { connect } from 'react-redux';
import Marquee from '../../Component/Utilities/Marquee/Marquee';

const mapStateToProps = (state) => {
    return {
        data: state.businessEntity.data,
        addOns: state.businessEntity.addOns,
        isLoaded: state.businessEntity.isLoaded
    };
}

const BusinessEntityPage = (props) => {
    const [ service, setService ] = useState({});
    const param = useParams();
    const [ code, setCode ] = useState('');
    const navigate = useNavigate();

    const [ transactCode, setTransactCode ] = useState(null);

    const renderService = (choosePackage = false) => {
        let data = props.data.hasOwnProperty(code) && !objectIsEmpty(props.data[code]) ? props.data[code] : {};
        let iconPath = data.iconPath;

        if( choosePackage ) {
            data = data.priceList.find( item => item.business_entity_code === transactCode );
            data = {
                code: data.business_entity_code,
                name: data.business_entity_name,
                iconPath,
                price: data.price,
                status: data.status
            };
        }

        setService({
            ...service,
            data,
            addOns: {...props.addOns}
        });
    }

    useEffect( () => {
        props.checkPage('pendirian-badan-usaha');

        if( param.hasOwnProperty('businessEntityCode') && param.businessEntityCode !== '' ) {
            setCode( param.businessEntityCode );
        } else {
            navigate('/');
        }

    }, []);

    useEffect( () => {
        let goBack = -1;

        if( transactCode !== null ) {
            goBack = () => {
                setTransactCode(null);
            }

            renderService(true);
        } else {
            renderService();
        }

        props.goBack(goBack);
        
    }, [ transactCode ]);

    useEffect( () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

        renderService();
    }, [ props.data, props.isLoaded, code ]);

    const chooseEntity = ( entityCode ) => {
        setTransactCode( entityCode );
    };

    return (
        !objectIsEmpty(service) && !objectIsEmpty(service.data) ? (
            <div className='business-entity-page'>
                <Marquee>
                    Baca lebih lanjut ketentuan pemesanan kami <a href={`${ process.env.REACT_APP_COMPANY_PROFILE_WEB_URL }terms-and-condition`} target="_blank">disini</a>.
                </Marquee>
                {
                    transactCode !== null ? (
                        <BusinessEntityDetail service={service} />
                    ) : (
                        <BusinessEntityList serviceData={service} choose={(entityCode) => chooseEntity(entityCode)} />
                    )
                }
            </div>
        ) : (
            <div className="business-entity-loading-container">
                <LoadingPage />
            </div>
        )
    );
}

export default connect(mapStateToProps)(BusinessEntityPage);