import { getToken } from '../Redux/Auth/auth-action';
import api from './API';
import Cookie from 'universal-cookie';

const cookies = new Cookie();

const basePath = 'auth';
const pathList = {
    me: `/me`,
    account: `/me/account`,
    profile: `/me/profile`,
    logout: `/logout`,
    deleteAccount: `/me/account-delete`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const isAuthenticated = async (argToken) => {
    try {
        const apiPath = pathList.me;
        const response = await api.getCall(apiPath, null, {}, argToken);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const saveAccount = async (request) => {
    try {
        const authToken = getToken();

        const apiPath = pathList.account;
        const response = await api.putCall(apiPath, null, request, authToken);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const saveProfile = async (request) => {
    try {
        const authToken = getToken();

        const apiPath = pathList.profile;
        const response = await api.putCall(apiPath, null, request, authToken);

        console.log(response);

        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const removeAuth = async (argToken) => {
    try {
        const apiPath = pathList.logout;
        const response = await api.getCall(apiPath, null, {}, argToken);
    
        return response;
    } catch(e) {
        console.log(e.toString());
    }
};

const deleteAccount = async ( argToken ) => {
    try {
        const apiPath = pathList.deleteAccount;
        const response = await api.deleteCall(apiPath, null, {}, argToken);

        return response;
    } catch(e) {
        console.log(e.toString());
        return e?.response?.data;
    }
};

const authAPI = {
    isAuthenticated,
    saveAccount,
    saveProfile,
    removeAuth,
    deleteAccount
}

export default authAPI;