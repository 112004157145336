import React from 'react';
import './EventItem.scss';

const EventItem = (props) => {
    const {
        eventBanner,
        eventName,
        eventDesc
    } = props;

    return (
        <div className='event-item'>
            <div className="event-banner">
                <img src={ `${process.env.REACT_APP_API_EVENT_STORAGE}${eventBanner}` } />
            </div>
            <div className="event-content">
                <div className="event-name">
                    { eventName }
                </div>
                <div className="event-desc">
                    { eventDesc }
                </div>
            </div>
        </div>
    );
}

EventItem.defaultProps = {
    eventBanner: '',
    eventName: '',
    eventDesc: ''
}

export default EventItem;