import Init from '../Init/Init';
import { buildWaLink } from '../Util/Util';

const PricingInfo = () => {
    const { sectionAssets } = Init.config;
    const { serviceType } = Init.constants;
    const orderLink = (serviceType, serviceCode = '') => `order/${serviceType}/${serviceCode}`;
    const legalServiceLink = (serviceCode) => orderLink(serviceType.legalService, serviceCode);
    const packageLink = (serviceCode) => orderLink(serviceType.package, serviceCode);

    return {
        individual: {
            name: 'Individual',
            title: 'Butuh Landack. untuk?',
            prices: [
                {
                    name: 'Konsultasi via Chat',
                    icon: sectionAssets.individualPricing.consultation,
                    description: `Jangan dipendam, chat Landack. sekarang untuk segala masalahmu`,
                    price: 'Rp15.000 / 15 menit',
                    buttonLink: legalServiceLink('KH'),
                    buttonWa: buildWaLink('konsultasi'),
                    actionLabel: 'Chat Consultation'
                },
                {
                    name: 'Review Dokumen*',
                    icon: sectionAssets.individualPricing.docReview,
                    description: `Ingin lebih pasti? Landack. bisa bantu cek dokumen legalmu`,
                    price: 'Rp100.000 / halaman',
                    buttonLink: legalServiceLink('RD'),
                    buttonWa: buildWaLink('minta review dokumen'),
                    actionLabel: 'Document Review'
                },
                {
                    name: 'Pembuatan Perjanjian*',
                    icon: sectionAssets.individualPricing.agreement,
                    description: `Perjanjian yang dibuat Landack. selalu menjagamu`,
                    price: 'Rp3.000.000 / dokumen',
                    buttonLink: legalServiceLink('PP'),
                    buttonWa: buildWaLink('minta dibuatkan dokumen perjanjian'),
                    actionLabel: 'Document Creation | Agreement Docs'
                },
                {
                    name: 'Pendaftaran Merek',
                    icon: sectionAssets.individualPricing.brandRegistration,
                    description: `Takut merek buatan mu dicuri orang? Daftarkan merek mu lewat Landack.`,
                    price: 'Rp3.700.000 / kelas merek',
                    buttonLink: legalServiceLink('MRK'),
                    buttonWa: buildWaLink('mendaftarkan merek'),
                    actionLabel: 'Brand Registration'
                },
                {
                    name: 'Perjanjian Kerahasiaan*',
                    icon: sectionAssets.individualPricing.nda,
                    description: `Supaya rahasiamu selalu aman`,
                    price: 'Rp500.000 / dokumen',
                    buttonLink: legalServiceLink('NDA'),
                    buttonWa: buildWaLink('minta dibuatkan dokumen perjanjian kerahasiaan'),
                    actionLabel: 'Document Creation | Non Disclosure Agreement'
                },
                {
                    name: 'Surat Somasi*',
                    icon: sectionAssets.individualPricing.somation,
                    description: `Hak mu dilangkahi? Somasi dari Landack. solusinya`,
                    price: 'Rp500.000 / dokumen',
                    buttonLink: legalServiceLink('SS'),
                    buttonWa: buildWaLink('minta dibuatkan surat somasi'),
                    actionLabel: 'Document Creation | Somation'
                },
                {
                    name: 'Jawaban Somasi*',
                    icon: sectionAssets.individualPricing.somationAns,
                    description: `Merasa benar? Sini Landack. bantu susun jawabannya`,
                    price: 'Rp500.000 / dokumen',
                    buttonLink: legalServiceLink('JS'),
                    buttonWa: buildWaLink('minta dibuatkan jawaban somasi'),
                    actionLabel: 'Document Creation | Somation Answer'
                },
                {
                    name: 'Uji Tuntas Hukum*',
                    icon: sectionAssets.individualPricing.ldd,
                    description: `Landack. bantu cek perusahaan/transaksi, dari segi hukum, untukmu`,
                    price: 'Rp50.000.000 / dokumen',
                    buttonLink: legalServiceLink('LDD'),
                    buttonWa: buildWaLink('minta uji tuntas hukum'),
                    actionLabel: 'Document Creation | Legal Due Diligence'
                }
            ],
            notes: [
                '*Seluruh layanan Landack. merupakan hasil kerja sama Landack. dengan Kantor Hukum bersertifikasi'
            ]
        },
        corporate: {
            name: 'Corporate',
            title: 'Landack. untuk usahamu',
            prices: [
                {
                    name: 'Paket Perunggu',
                    icon: sectionAssets.corporatePricing.bronze,
                    descriptionList: [
                        '✅ Pembuatan Kontrak 3x',
                        '✅ Konsultasi Chat 30 x 30 menit*',
                        '✅ Pembuatan Perjanjian Kerahasiaan 4x',
                        '✅ Review Dokumen 100 halaman',
                        '❌ Konsultasi Tatap Muka'
                    ],
                    priceList: [
                        '1 Bulan: Rp10.000.000 / bulan',
                        '3 Bulan: Rp9.000.000 / bulan',
                        '6 Bulan: Rp8.000.000 / bulan'
                    ],
                    priceObj: {
                        realPrice: 'Rp20.900.000',
                        discountPrice: 'Rp10.000.000',
                        savePercentage: '(Hemat sampai 51%)'
                    },
                    buttonLink: packageLink('KPU'),
                    buttonWa: buildWaLink('berlangganan dengan Paket Perunggu'),
                    isBestSeller: true,
                    actionLabel: 'Paket Perunggu'
                },
                {
                    name: 'Paket Perak',
                    icon: sectionAssets.corporatePricing.silver,
                    descriptionList: [
                        '✅ Pembuatan Kontrak 6x',
                        '✅ Konsultasi Chat 60 x 30 menit*',
                        '✅ Pembuatan Perjanjian Kerahasiaan 4x',
                        '✅ Review Dokumen 200 halaman',
                        '✅ Konsultasi Tatap Muka 2 x 2 jam'
                    ],
                    priceList: [
                        '1 Bulan: Rp20.000.000 / bulan',
                        '3 Bulan: Rp18.000.000 / bulan',
                        '6 Bulan: Rp16.000.000 / bulan',
                    ],
                    priceObj: {
                        realPrice: 'Rp45.800.000',
                        discountPrice: 'Rp20.000.000',
                        savePercentage: '(Hemat sampai 56,4%)'
                    },
                    buttonLink: packageLink('KPA'),
                    buttonWa: buildWaLink('berlangganan dengan Paket Perak'),
                    isRecommended: true,
                    actionLabel: 'Paket Perak'
                },
                {
                    name: 'Paket Emas',
                    icon: sectionAssets.corporatePricing.gold,
                    descriptionList: [
                        '✅ Pembuatan Kontrak 15x',
                        '✅ Konsultasi Chat Unlimited*',
                        '✅ Pembuatan Perjanjian Kerahasiaan 10x',
                        '✅ Review Dokumen 500 halaman',
                        '✅ Konsultasi Tatap Muka 5 x 2 jam**',
                    ],
                    priceList: [
                        '1 Bulan: Rp50.000.000 / bulan',
                        '3 Bulan: Rp48.000.000 / bulan',
                        '6 Bulan: Rp45.000.000 / bulan',
                    ],
                    priceObj: {
                        realPrice: 'Rp123.200.000',
                        discountPrice: 'Rp50.000.000',
                        savePercentage: '(Hemat sampai 59,4%)'
                    },
                    buttonLink: packageLink('KPE'),
                    buttonWa: buildWaLink('berlangganan dengan Paket Emas'),
                    actionLabel: 'Paket Emas'
                }
            ],
            notes: [
                '*Konsultasi akan dilayani dengan admin Landack. khusus selama jam operasional',
                '**Harga tidak termasuk transportasi dan akomodasi jika dilakukan diluar JABODETABEK',
                '***Untuk layanan diatas 1 bulan, wajib dibayarkan secara sekaligus diawal'
            ]
        }
    };
}

export default PricingInfo;