import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './LegalServicePage.scss';

import LegalServiceDetail from '../../Component/PagesComponent/LegalServiceDetail/LegalServiceDetail';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';
import Marquee from '../../Component/Utilities/Marquee/Marquee';

import { objectIsEmpty } from '../../Util/Util';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        data: state.legalService.data,
        isLoaded: state.legalService.isLoaded
    };
}

const LegalServicePage = (props) => {
    const [ service, setService ] = useState({});
    const param = useParams();
    const [ code, setCode ] = useState('');
    const navigate = useNavigate();

    useEffect( () => {
        props.checkPage('layanan-hukum');

        if( param.hasOwnProperty('serviceCode') && param.serviceCode !== '' ) {
            setCode( param.serviceCode );
        } else {
            navigate('/');
        }

    }, []);

    useEffect( () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

        let data = props.data.hasOwnProperty(code) && !objectIsEmpty(props.data[code]) ? props.data[code] : {};
        
        setService({
            ...service,
            data
        });
    }, [ props.data, props.isLoaded, code ]);

    return (
        <div className='legal-service-page'>
            <Marquee>
                Baca lebih lanjut ketentuan pemesanan kami <a href={`${ process.env.REACT_APP_COMPANY_PROFILE_WEB_URL }terms-and-condition`} target="_blank">disini</a>.
            </Marquee>
            {
                !objectIsEmpty(service) && !objectIsEmpty(service.data) ? (
                    <LegalServiceDetail serviceData={service} />
                ) : (
                    <div className="legal-service-loading-container">
                        <LoadingPage />
                    </div>
                )
            }
        </div>
    );
}

export default connect(mapStateToProps)(LegalServicePage);