import { bannerList } from '../../Data/Banner';
import * as actionTypes from './banner-types';

const loadBanner = () => {
    return dispatch => {
        bannerList().then( (response) => {
            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                let data = response.data;

                dispatch(loadBannerSuccess(data));
            }
        } );
    }
}

const loadBannerSuccess = (data) => {
    return {
        type: actionTypes.BANNER_LIST,
        payload: {
            data
        }
    }
}

const bannerActions = {
    loadBanner
}

export default bannerActions;