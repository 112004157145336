import * as actionTypes from './auth-types';

const INITIAL_STATE = {
    isAuthenticating: false,
    isLogin: false,
    data: {}
}

const authReducer = (state = INITIAL_STATE, action) => {
    let { isAuthenticating, isLogin, data } = state;
    
    switch(action.type) {
       case actionTypes.AUTHENTICATE:
            isAuthenticating = false;
            isLogin = true;
            data = action.payload.data;

            break;
        case actionTypes.AUTHENTICATING:
            isAuthenticating = true;

            break;
        case actionTypes.AUTHENTICATE_FAILED:
            isAuthenticating = false;
        
            break;
        case actionTypes.LOGOUT:
            isAuthenticating = false;
            isLogin = false;
            data = {};

            break;
        case actionTypes.DELETE_ACC_SUCCESS:
            break;
        default: 
            break;
    }

    return {
        ...state,
        isAuthenticating,
        isLogin,
        data
    };
};

export default authReducer;