import * as actionTypes from './package-types';

const INITIAL_STATE = {
    data: {},
    list: [],
    isLoaded: false
}

const packageReducer = (state = INITIAL_STATE, action) => {
    let { data, list } = state;
    
    switch(action.type) {
       case actionTypes.PACKAGE_LIST:
            list = action.payload.data;

            for(let index in list) {
                let item = list[index];
                let totalRealPrice = parseInt(item.fake_price);
                let savedPrice = parseInt(item.package_pricing[0].monthly_price);

                data[item.package_code] = {
                    code: item.package_code,
                    name: item.package_name,
                    iconPath: item.icon_path,
                    appIconPath: item.app_icon_path,
                    isRecommended: item.is_recommended,
                    isBestSeller: item.is_best_seller,
                    descriptionList: item.package_service,
                    priceObj: {
                        realPrice: totalRealPrice,
                        discountPrice: savedPrice,
                        savePercentage: Math.floor(((totalRealPrice - savedPrice)/totalRealPrice) * 100) + '%'
                    },
                    packageDescription: item.package_description
                }
            }

            return {
                ...state,
                list,
                data,
                isLoaded: true
            };
       default: 
            return state;
    }
};

export default packageReducer;